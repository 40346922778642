import React from 'react';
import styles from './index.module.css';
import { Typography, Button } from '@mui/material';
import { BsPower } from 'react-icons/bs';
import { CgMenuGridO } from 'react-icons/cg';
import { useApplicationContext } from '../../../context/application';

const HeaderBar = ({ onVisibled }) => {
    const { onLogout, userName, lastName } = useApplicationContext();
    return (
        <div className={styles.container} >

            <Button onClick={onVisibled} endIcon={<CgMenuGridO size={40} color='white' />} />

            <div>
                <Typography className={styles.welcome} > Bem vindo </Typography>
                <Typography className={styles.welcomeName} > {userName} {lastName} </Typography>
            </div>

            <Button title='sair' onClick={() => onLogout()} >
                <BsPower size={25} style={{ color: 'white' }} />
            </Button>
        </div>
    )
}

export default HeaderBar;