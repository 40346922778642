import React, { useState } from 'react';
import styles from './styles.module.css';
import MobileHeaderBar from '../../components/HeaderBar';
import MobileNavigateDrawer from '../../components/NavigateDrawer';
import { Outlet } from 'react-router-dom';

const MobileSales = () => {

    const [state, setState] = useState({
        drawerVisibled: false,
    });

    let { drawerVisibled } = state;

    const onVisibled = () => {
        setState({ ...state, drawerVisibled: !drawerVisibled });
    }

    return (
        <div className={styles.container} >
            <MobileHeaderBar onVisibled={onVisibled} />
            <MobileNavigateDrawer drawerVisibled={drawerVisibled} onVisibled={onVisibled} />

            <Outlet />
            
        </div>
    )
}

export default MobileSales;