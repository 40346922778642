import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TypeApplicationContext } from '../../interfaces/context';
import { apiOnCheckToken } from '../../serverless/auth';
import { local_db } from '../../utils/local_db';
import moment from 'moment/moment';
import Cookies from 'js-cookie';

const ApplicationContext = createContext({
    ...new TypeApplicationContext(),
    onLogin: (userCPF, password) => { },
    onLogout: () => { },
});


const ApplicationProvider = ({ children }) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [state, setState] = useState({
        ...new TypeApplicationContext(),
    });

    // eslint-disable-next-line
    useEffect(() => { onLoggedIn() }, [location]);

    
    useEffect(() => {
        onCheckToken();
        // eslint-disable-next-line
    }, []);

    const onCheckToken = async () => {
        try {
            const checkToken = await apiOnCheckToken();
            const token = Cookies.get('st-token')
            // console.log('Existe', checkToken, token)
            if (!checkToken && !token) {
                localStorage.removeItem('user');
                Cookies.remove('st-token');
                if (location.pathname !== '/login' && location.pathname !== '/') navigate('/login', { replace: true })
        
            } else {
                console.log('user auth check');
            }
        } catch (error) {
            console.log('Check Token ERR: ', error);
            
        }
    }

    const onLoggedIn = async () => {
        const token = Cookies.get('st-token');
        // console.log('->', token);
        if (token) {
            const data = await JSON.parse(localStorage.getItem('user'));
            if (data !== null) {

                setState({ ...state, ...data })
            }

        } else {
            console.log(2)
            // localStorage.removeItem('user');
            // Cookies.remove('st-token');
            // if (location.pathname !== '/login' && location.pathname !== '/') navigate('/login', { replace: true })
        }
    }

    const onLogin = (response) => {
        const dateTime = moment().format('DD/MM/YYYY hh:mm');
        Cookies.set('st-token', response?.token, { expires: 7, secure: true });
        delete response?.token;
        localStorage.setItem('user', JSON.stringify({
            ...response,
            isLogged: true,
            dateTime,
        }));
        setState({
            ...response,
            isLogged: true,
            dateTime,
        });
        // const timeToken = new Date();
        // timeToken.setTime(timeToken.getTime() + (60 * 1000)) // 60 segundos
        // TIME em segundos
    }

    const onLogout = () => {
        // alert('deslogar mesmo ?')
        setState({
            ...new TypeApplicationContext(),
            isLogged: false,
        });
        localStorage.removeItem('user');
        Cookies.remove('st-token');
        local_db?.delete();
        navigate('/', { replace: true });
    }

    return (
        <ApplicationContext.Provider value={{ ...state, onLogin, onLogout }} >
            {children}
        </ApplicationContext.Provider>
    )
}

export function useApplicationContext() {
    const context = useContext(ApplicationContext);
    return context;
}

export default ApplicationProvider;