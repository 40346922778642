import React, { useState } from 'react';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Typography } from '@mui/material';
import { onCheckin } from '../../../serverless/auth';
import { useApplicationContext } from '../../../context/application';
import { onFormatCPF, removeCaracters } from '../../../utils/formatCPF';
import { Logo64 } from '../../../images';

const MobileLogin = () => {

    const [state, setState] = useState({
        userCPF: '',
        password: '',
    })

    let { userCPF, password } = state;
    const { onLogin } = useApplicationContext();
    const navigate = useNavigate();

    const onChangeInput = (key, value) => {
        state[key] = value;
        setState({ ...state });
    }

    const onChangeCheckin = () => {
        const CPF = removeCaracters(userCPF);
        onCheckin(CPF, password)
            .then((response) => {

                if (response.length >= 1) {
                    onLogin(response[0])
                    navigate('/panel', { replace: true });
                } else {
                    console.log('NOT ACCESS', response)
                }
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    return (
        <div className={styles.container} >

            <img src={Logo64} alt="Logo white" width={50} />

            <Typography className={styles.title} > Syntecn acesso </Typography>

            <TextField
                className={styles.input}
                label='CPF'
                autoComplete='off'
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: { height: 45 } }}
                value={userCPF}
                onChange={(e) => e.target.value.length <= 14 ? onChangeInput('userCPF', onFormatCPF(e.target.value)) : {}}
                onKeyDown={(e) => e.key === 'Enter' && userCPF.length >= 14 && password.length >= 5 ? onChangeCheckin() : {}} />

            <TextField
                className={styles.input}
                label='Senha'
                autoComplete='off'
                type={'password'}
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: { height: 45 } }}
                value={password}
                onChange={(e) => onChangeInput('password', e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && userCPF.length >= 14 && password.length >= 5 ? onChangeCheckin() : {}} />

            <Button
                className={styles.entrar}
                variant='contained'
                onClick={onChangeCheckin}
                disabled={userCPF.length >= 14 && password.length >= 5 ? false : true}
            >
                entrar
            </Button>

            <Button className={styles.help_bt} > Help </Button>

        </div>
    )
}

export default MobileLogin;