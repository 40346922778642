import React, { useEffect } from 'react';
import styles from './styles.module.css';
import { Button, Typography } from '@mui/material';
import { LogoWhite64 } from '../../../images';
import { DiLinux } from 'react-icons/di';
import { BsWindows, BsApple, BsCheck2 } from 'react-icons/bs';
import { HiOutlineDeviceMobile } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useApplicationContext } from '../../../context/application';

const MobileHome = () => {

    const navigate = useNavigate();
    const { isLogged } = useApplicationContext();

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        if (isLogged) {
            navigate('panel', { replace: true });
        }
    }

    const listServices = [
        { id: 0, title: `Gestão Empresarial`, text: `Tenha suas tarefas administrativas gerenciadas de maneira pratica e objetiva, englobando seus produtos, serviços, vendas, relatórios e todas os seus processos gerenciais sempre disponíveis para alancar seu negócio.` },
        { id: 1, title: `Gestão Administrativa`, text: `Mantenha sempre seus controle, agilize seus processos e tenha melhor utilização dos seus recursos sempre de facil acesso e grande controle.` },
        { id: 2, title: `Gestão de Ensino`, text: `` },
        { id: 3, title: `Gestão de Saúde`, text: `` },
        { id: 4, title: `Gestão de Personalizada`, text: `` },
    ]

    const listDisp = [
        { id: 0, title: `Disponível na Web`, text: `` },
        { id: 1, title: `Disponível na Mobile`, text: `` },
        { id: 2, title: `Disponível para Windows`, text: `` },
        { id: 3, title: `Disponível para IOS`, text: `` },
        { id: 4, title: `Disponível para Linux`, text: `` },
    ]

    return (
        <div className={styles.container} >

            <div className={styles.header} >
                <Typography className={styles.title} >SynTecn</Typography>
                <Typography className={styles.subTitle} >Sistemas, soluções e inovações</Typography>
                <img src={LogoWhite64} alt="Logo white" width={45} />
                {/* <DiCodeigniter color='white' size={30} style={{ marginBottom: '2vh', marginTop: '2vh' }} /> */}
            </div>

            <Button variant='contained' onClick={() => navigate(isLogged ? 'panel' : 'login', { replace: true })} > Acessar painel </Button>

            <div className={styles.cardOS} >
                <BsWindows color='royalblue' size={30} />
                <BsApple color='royalblue' size={30} />
                <DiLinux color='royalblue' size={30} />
                <HiOutlineDeviceMobile color='royalblue' size={30} />
            </div>

            <div className={styles.cardIntro} >
                <Typography className={styles.introTitle} >SYNTECN</Typography>
                <Typography className={styles.introText} >
                    Syntecn é uma empresa que disponibiliza plataformas de gestão
                    para diverças áreas de atuação, através de serviços de sistemas de informação.
                    Mantendo seus serviços sempre em alta disponibilidade em serviços de Cloud de alta performace.
                    A Syntecn também dispõe de aplicações personalizadas para uma maior flexibilidade de seus serviços.
                </Typography>
            </div>

            <div className={styles.cardList} >
                <Typography className={styles.modelsTitle} > Modelos de Gestão </Typography>
                {listServices.map((item, key) => (
                    <Typography key={key} className={styles.itemList} > {item.title} <BsCheck2 color='seagreen' size={30} /> </Typography>
                ))}
            </div>

            <div className={styles.cardList} >
                <Typography className={styles.modelsTitle} > Plataformas </Typography>
                {listDisp.map((item, key) => (
                    <Typography key={key} className={styles.itemList} > {item.title} <BsCheck2 color='seagreen' size={30} /> </Typography>
                ))}
            </div>

        </div>
    )
}

export default MobileHome;