
export class TypeProducts {
    id = 0; 
    name = ''; 
    descripition = ''; 
    codeBar = ''; 
    qtde = 0; 
    value = 0; 
    value_time = 0; 
    value_percentage = 0; 
    measure = ''; 
    active = true; 
    last_supplier = 0; 
    note = ''; 
    created = ''; 
    updated = ''; 
    last_iam = 0;

    // constructor() {

    // }
}

