import { serverSales as server } from '../server';

export const apiOnSaleAdd = (data) => {

    return new Promise((resolve, rejetc) => {
        server.post('/register', data )
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err.response.data);
            })
    })
}

export const apiOnSaleListDay = (data) => {

    return new Promise((resolve, rejetc) => {
        server.post('/listDay', data )
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err.response.data);
            })
    })
}