import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

export const DialogConfirm = ({isOpen, onClose, title, text, subText, name, id, onConfirm}) => {
    return (
        <Dialog open={isOpen} keepMounted onClose={onClose} >
            <DialogTitle> {title} </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <p> {text} <b style={{ textTransform: 'capitalize' }} >{name}</b> </p>
                    <p style={{ color: 'tomato' }} > {subText} </p>
                </DialogContentText>

            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={() => onConfirm(id)}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    )
}