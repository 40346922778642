import React, { useState } from 'react';
import styles from './styles.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Collapse, Dialog, DialogTitle, TextField, Typography } from '@mui/material';
import { FaUserTag } from 'react-icons/fa';
import { BsCheck2 } from 'react-icons/bs';
import { MdEdit } from 'react-icons/md';
import { apiOnSaleAdd } from '../../../../serverless/sales';
import { onProductExit } from '../../../../serverless/products';
import { onFormatCurrent } from '../../../../utils/currenty';
import { onFormatCPF, removeCaracters } from '../../../../utils/formatCPF';
import { apiOnClient } from '../../../../serverless/customers';

const Payment = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        options: [
            { title: 'Dinheiro', select: false, desc: 'Valor R$', input: '', edite: false, value: '' },
            { title: 'Cartão', select: false, desc: 'Valor R$', input: '', edite: false, value: '' },
            { title: 'Pix', select: false, desc: 'Valor R$', input: '', edite: false, value: '' },
            { title: 'Cliente', select: false, desc: 'CPF ou NOME', input: '', edite: false, value: '' },
        ],
        sale: { value_sale: 0, qtde_itens: 0, format_payment: '', note_sale: '', id_IAM: '', details: '', name_client: '', cpf_client: '' },
        note: '',
        getClient: { name: "", cpf: "", phone: "", email: "", limite: "", invoice: "", note: "" },
        progress: { in: false, text: '' },
        messegerBD: '',
    });

    let { options, sale, note, progress, getClient, messegerBD } = state;

    const onSelect = (key) => {
        options[key].select = !options[key].select;
        sale.format_payment = '';
        options.filter((i) => i.select === true).map((i, k) => sale.format_payment += `${i.title} - `);
        // console.log(options.filter((i) => i.select).length, sale.format_payment);
        setState({ ...state, options, sale });
    }

    const onValuePayment = () => {
        let value = 0;
        location.state.map((i) => value += (Number.parseFloat(i.value) * i.qtde))
        return value.toFixed(2);
    }

    const onInputValues = (key, title, value) => {
        if (title === 'Cliente') {
            if (isNaN(value.substring(0, 1))) {
                options[key].input = value;
            } else if (value.length <= 14) {
                options[key].input = onFormatCPF(value);
            }
        } else if (title !== 'Cliente' && value.length <= 9) {
            options[key].input = onFormatCurrent(value);
        }
        setState({ ...state, options });
    }

    const onCalcTroco = (input) => {
        let valuePayment = Number.parseFloat(onValuePayment());
        let valueInput = Number.parseFloat(input.replace('.', ''));
        if (valueInput >= 1) {
            return (valueInput - valuePayment).toFixed(2);
        } else {
            return '0.00';

        }
    }

    const onGetClient = async (code, CPF_NAME) => {

        if ((code === 'Enter' || code === 'NumpadEnter') && CPF_NAME.length) {
            let CPF_NAME_RES = CPF_NAME;
            if (!isNaN(CPF_NAME.substring(0, 1)) && CPF_NAME.length === 14) {
                CPF_NAME_RES = removeCaracters(CPF_NAME);
            }
            // console.log(CPF_NAME_RES);
            let dataClient = await apiOnClient(CPF_NAME_RES);
            if (dataClient.length) setState({ ...state, getClient: dataClient[0] });
            // console.log(getClient?.name);
        } else if (code === 'Enter' && !CPF_NAME.length) {
            getClient = { cpf: '', email: '', invoice: '', limite: '', name: '', note: '', phone: '' };
            setState({ ...state, getClient });
        }
    }

    const onFinish = () => {

        sale.id_IAM = JSON.parse(localStorage.getItem('user'))?.userCPF || "-1";
        // sale.cpf_client = removeCaracters(options.filter((i) => i.title === 'Cliente')[0].input);
        sale.cpf_client = removeCaracters(getClient.cpf);
        sale.name_client = getClient.name;
        // console.log(sale.cpf_client)
        // NAME esta vazio, colocar depois
        // sale.qtde_itens = location.state.length;
        location.state.map((i) => sale.qtde_itens += i.qtde)
        sale.value_sale = Number.parseFloat(onValuePayment());
        sale.note_sale = note;
        sale.details = `VALOR TOTAL: ${onValuePayment()} \n \n`;

        location.state.map((i) => sale.details += `CODEBAR: ${i.codeBar} \nNAME: ${i.name} \nÚLTIMO USER: ${i.last_iam} \nQTDE: ${i.qtde} \nVALOR: ${i.value} \n \n`);

        apiOnSaleAdd(sale).then(async (res) => {
            progress.in = true;

            await Promise.all(location.state.map(async (i) => {
                let data = { id: i.id, qtde: i.qtde };
                await onProductExit(data).catch(() => console.log('Erro em SAIDA de item: ', i.id));
            }));

            progress.in = false;
            console.log('fim');
            localStorage.removeItem('arrProducts');
            navigate('/panel/pointsale/finish', { state: { status: 'ok' }, replace: true });
        }).catch((err) => {
            // alert('Não foi possível finalizar, tente novamente ou reporte o erro.');
            console.log('ERRO apiOnSaleAdd: ', err);
            messegerBD = `Não Conseguimos enviar os dados agora.\nDeseja salvar local e enviar mais tarde ? `;
            setState({ ...state, messegerBD });
        })


    }

    const CardMoney = ({ inputValue }) => {

        return (
            <div>
                <Typography className={styles.troco} > TROCO: {onCalcTroco(inputValue)} </Typography>
            </div>
        )
    }

    // eslint-disable-next-line
    const CardCart = ({ inputValue }) => {

        return (
            <div>
                <Typography className={styles.troco} > TROCO: {onCalcTroco(inputValue)} </Typography>
            </div>
        )
    }

    // eslint-disable-next-line
    const CardPix = ({ inputValue }) => {

        return (
            <div>
                <Typography className={styles.troco} > TROCO: {onCalcTroco(inputValue)} </Typography>
            </div>
        )
    }

    const CardClient = ({ inputValue }) => {

        return (
            <div>
                <Button
                    onClick={() => onGetClient('Enter', inputValue)}
                    endIcon={<FaUserTag color='seagreen' size={20} />} >{`${getClient?.name} ${getClient?.cpf.length ? ' - ' : ''} ${getClient?.cpf.replace(/^(\d{3})(\d{3})(\d{3})/, '$1.$2.$3-')}`}
                </Button>
            </div>
        )
    }

    const onSaveLocal = async () => {
        setState({ ...state, messegerBD: '' });

        try {
            sale.id_IAM = JSON.parse(localStorage.getItem('user'))?.userCPF || "-1";
            sale.cpf_client = removeCaracters(getClient.cpf);
            sale.name_client = getClient.name;

            location.state.map((i) => sale.qtde_itens += i.qtde)
            sale.value_sale = Number.parseFloat(onValuePayment());
            sale.note_sale = note;
            sale.details = `VALOR TOTAL: ${onValuePayment()} \n \n`;

            location.state.map((i) => sale.details += `CODEBAR: ${i.codeBar} \nNAME: ${i.name} \nÚLTIMO USER: ${i.last_iam} \nQTDE: ${i.qtde} \nVALOR: ${i.value} \n \n`);

            // localStorage.removeItem('sale');
            const saleDB = JSON.parse(localStorage.getItem('sale'));
            if (saleDB) {
                // console.log('exist', saleDB[0]?.format_payment);
                saleDB.push(sale);
                localStorage.setItem('sale', JSON.stringify(saleDB));
            } else {
                localStorage.setItem('sale', JSON.stringify([sale]));

            }
            
            let lessProductsDB = JSON.parse(localStorage.getItem('lessProducts'));
            let lessProduct = {};
            await Promise.all(location.state.map(async (i) => {
                lessProduct = { id: i.id, qtde: i.qtde };
                if (lessProductsDB) {
                    lessProductsDB.push(lessProduct);
                } else {
                    lessProductsDB = [lessProduct]
                }
            }));
            localStorage.setItem('lessProducts', JSON.stringify(lessProductsDB));

            localStorage.removeItem('arrProducts');
            navigate('/panel/pointsale/finish', { state: { status: 'ok' }, replace: true });

        } catch (error) {
            console.log('ERRO ');
            alert('Ocorreu um erro. Tente novamente mais tarde');
        }
    }

    // console.log(location.state)
    return (
        <div className={styles.container} style={{ pointerEvents: progress.in ? 'none' : 'auto' }} >

            <Typography className={styles.titleValue} > R$ {onValuePayment()} </Typography>

            <div className={styles.options} >

                {options.map((option, key) => (
                    <div key={key} >
                        <Button onClick={() => onSelect(key)} variant={option.select ? 'contained' : 'outlined'} > {option.title} </Button>
                        <Collapse in={option.select} unmountOnExit >
                            <div>
                                {option.edite ?
                                    <div>
                                        <TextField value={option.value} onChange={(e) => {
                                            option.value = e.target.value.replace(/^(\d{3})(\d{3})(\d{3})/, '$1.$2.$3-');
                                            setState({ ...state, options });
                                        }} />
                                        <BsCheck2 onClick={() => {
                                            option.edite = !option.edite;
                                            setState({ ...state, options });
                                        }} />
                                    </div>
                                    :
                                    <div>
                                        <Typography>{option?.value !== '' ? option?.value : onValuePayment()}</Typography>
                                        <MdEdit onClick={() => {
                                            option.edite = !option.edite;
                                            setState({ ...state, options });
                                        }} />
                                    </div>
                                }
                                <TextField
                                    label={option.desc}
                                    autoComplete='off'
                                    placeholder={!option.title.includes('Cliente') ? onValuePayment() : '000.000.000-00'}
                                    InputLabelProps={{ shrink: true }}
                                    value={option.input}
                                    onKeyDown={(e) => option.title === 'Cliente' ? onGetClient(e.code, option.input) : {}}
                                    onChange={(e) => onInputValues(key, option.title, e.currentTarget.value)} />
                                {option.title === 'Dinheiro' ? <CardMoney inputValue={option.input} /> : null}
                                {/* {option.title === 'Cartão' ? <TextField fullWidth label='Autorização' placeholder='digite' InputLabelProps={{ shrink: true }} InputProps={{ style: { height: 45 } }} style={{ marginTop: 20 }} /> : null} */}
                                {option.title === 'Cliente' ? <CardClient inputValue={option.input} /> : null}
                            </div>

                        </Collapse>
                    </div>
                ))}

            </div>

            {progress.in ?
                <div style={{ marginTop: '5vh', marginBottom: '10vh' }} >
                    <CircularProgress size={30} />
                    <Typography>Enviando...</Typography>
                </div>
                :
                <Button
                    className={styles.BTfinish}
                    variant='contained'
                    disabled={options.filter((i) => i.select === true).length >= 1 ? false : true}
                    onClick={onFinish}
                >Finalizar</Button>}

            <TextField
                label='Nota'
                className={styles.note}
                autoComplete='off'
                placeholder='Opicional'
                value={note}
                onChange={(e) => setState({ ...state, note: e.currentTarget.value })}
                InputLabelProps={{ shrink: true }} />

            <Button
                disabled={options.filter((i) => i.select === true).length >= 1 ? false : true}
                onClick={() => setState({ ...state, messegerBD: 'Deseja salvar local e enviar mais tarde ? ' })} > Guardar offiline </Button>


            <Button> Reportar erro </Button>

            <Dialog open={messegerBD ? true : false} onClose={() => setState({ ...state, messegerBD: '' })} >
                <DialogTitle> {messegerBD} </DialogTitle>
                <Button onClick={onSaveLocal} >SIM</Button>
                <Button onClick={() => setState({ ...state, messegerBD: '' })} >NÃO</Button>
            </Dialog>

        </div>
    )
}

export default Payment;