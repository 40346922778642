import React from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';
import { Logo64 } from '../../../images';
// import { DiCodeigniter } from 'react-icons/di';

const LogoV = ({ sizeIMG }) => {
    // const image = sizeIMG === 32 ? iconGreen32 : sizeIMG === 64 ? iconGreen64 : sizeIMG === 96 ? iconGreen96 : iconGreen64;
    const fontSize = sizeIMG === 32 ? 10 : sizeIMG === 64 ? 14 : sizeIMG === 96 ? 20 : 14;
    return (
        <div className={styles.container} >
            <img src={Logo64} alt="Icon" width={sizeIMG} />
            <Typography className={styles.title} fontSize={fontSize} >SynTecn</Typography>
        </div>
    )
}

export default LogoV;