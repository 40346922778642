import React, { useEffect, useState } from "react";
import styles from './styles.module.css';
import moment from "moment";
import { apiOnSaleListDay } from "../../../../serverless/sales";
import { TypeSales } from "../../../../interfaces/sales";
import { CircularProgress, Typography } from "@mui/material";

const Day = () => {

    const [state, setState] = useState({
        list: [new TypeSales()],
        isLoading: '',
    });

    let { list, isLoading } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        setState({ ...state, list: list.splice(0) });

        try {
            const apiDate = {
                dateInit: moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss'),
                dateLast: moment().set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD HH:mm:ss'),
            }
            
            // const apiDateMonth = {
            //     dateInit: moment().set({ month: moment().month() + 1, hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss'),
            //     dateLast: moment().set({ month: moment().month() + 1, hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD HH:mm:ss'),
            // }
            

            list = await apiOnSaleListDay(apiDate);

            console.log(list, moment().month() )

            setState({ ...state, list, isLoading: 'ok' });

        } catch (error) {

        }
    }

    return (
        <div className={styles.container} >
            <Typography>List</Typography>
            {isLoading ? null : isLoading === 'ok' ? 'OK' : <CircularProgress />}
        </div>
    )
}

export default Day;