import React from 'react';
import styles from './styles.module.css';
import { LogoWhite32 } from '../../../images';
import { BsPower, BsList } from 'react-icons/bs';
import { Typography } from '@mui/material';
import { useApplicationContext } from '../../../context/application';

const MobileHeaderBar = ({ onVisibled }) => {

    const { userName } = useApplicationContext();

    return (
        <div className={styles.container}>

            <BsList size={30} color='white' style={{ marginLeft: 15 }} onClick={onVisibled} />

            <div>
                <img src={LogoWhite32} alt="Logo white" />
                <Typography> Syntecn </Typography>
            </div>

            <Typography className={styles.userName} > {userName} </Typography>
            <BsPower size={25} color='white' style={{ marginRight: 20 }} />
        </div>
    )
}

export default MobileHeaderBar;