import React, { useState } from 'react';
import styles from './styles.module.css';
import { Typography, Button, TextField, Snackbar, Alert } from '@mui/material';
import { apiOnSave } from '../../../../serverless/customers';
import { useApplicationContext } from '../../../../context/application';
import { onFormatCPF, removeCaracters } from '../../../../utils/formatCPF';
import { onFormatCurrent } from '../../../../utils/currenty';
import { convertCurrency } from '../../../../utils/NumberFormt';

const CustomersAdd = () => {

    const { userCPF } = useApplicationContext();

    const [state, setState] = useState({
        customer: { name: '', cpf: '', phone: '', email: '', dateBirth: '', limite: '5000', note: '', logIAM: '' },
        isMesseg: '',
    });

    let { customer, isMesseg } = state;

    const onState = (key, value) => {
        customer[key] = value;
        setState({ ...state, customer });
    }

    const onSave = () => {
        customer.logIAM = userCPF;
        customer.limite = convertCurrency(customer.limite);
        customer.cpf = customer.cpf ? removeCaracters(customer.cpf) : null;
        apiOnSave(customer).then((response) => {
            console.log(response)
            // alert(response.insertId)
            if (response.insertId) {
                onClean();
            } else {
                alert('ERRO')
            }
            // alert(response.insertId ? 'Salvo com sucesso' : 'Erro ao salvar')
            // onClean();
        }).catch((err) => {
            console.log(err)
            alert(err)
        })
    }

    const onClean = () => {
        customer = { name: '', cpf: '', phone: '', email: '', dateBirth: '', limite: '5000', note: '', logIAM: '' };
        setState({ ...state, customer, isMesseg: 'Salvo com sucesso' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const Messeger = () => {
        return (
            <Snackbar
                open={isMesseg ? true : false}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                // message={isMesseg}
                onClose={() => setState({ ...state, isMesseg: '' })} >

                <Alert
                    onClose={() => setState({ ...state, isMesseg: '' })}
                    severity='success'
                    sx={{ width: '100%' }} > {isMesseg} </Alert>

            </Snackbar>

        )
    }

    return (
        <div className={styles.container} >

            <Typography> Adiconar novo cliente </Typography>

            <div className={styles.cardInput} >
                <TextField
                    className={styles.input}
                    label='Nome'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 40 } }}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                    value={customer.name}
                    onChange={(e) => onState('name', e.target.value)}
                />

                <TextField
                    className={styles.input}
                    label='CPF'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 40 } }}
                    value={customer.cpf}
                    onChange={(e) => e.target.value.length <= 14 ? onState('cpf', onFormatCPF(e.target.value)) : {}}
                />
            </div>

            <div className={styles.cardInput} >
                <TextField
                    className={styles.input}
                    label='Telefone'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 40 } }}
                    value={customer.phone}
                    onChange={(e) => onState('phone', e.target.value)}
                />

                <TextField
                    className={styles.input}
                    label='Email'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 40 } }}
                    value={customer.email}
                    onChange={(e) => onState('email', e.target.value)}
                />
            </div>

            <div className={styles.cardInput} >
                <TextField
                    className={styles.input}
                    label='Data de Nascimento'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 40 } }}
                    value={customer.dateBirth}
                    onChange={(e) => onState('dateBirth', e.target.value)}
                />

                <TextField
                    className={styles.input}
                    label='Limite'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 40 } }}
                    value={customer.limite}
                    onChange={(e) => e.target.value.length <= 9 ? onState('limite', onFormatCurrent(e.target.value)) : {}}
                />
            </div>

            <div className={styles.cardInput} >
                <TextField
                    label='Observação'
                    fullWidth
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 40 } }}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                    value={customer.note}
                    onChange={(e) => onState('note', e.target.value)}
                />
            </div>

            <div className={styles.cardBTs} >
                <Button
                    variant='contained'
                    style={{ backgroundColor: 'tomato' }}
                    onClick={onClean} > Limpar </Button>

                <Button
                    variant='contained'
                    disabled={customer.name.length < 2 ? true : false}
                    onClick={onSave} > Salvar </Button>
            </div>

            <Messeger />

        </div>
    )
}

export default CustomersAdd;