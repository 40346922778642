
import { serverProducts as server } from '../server';
// import { typeProductsList, typeProductsListStoke } from '../../interfaces/products';

// const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';

export const onProductsList = () => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';
    
    return new Promise((resolve, reject) => {
        server.post('/list', { db_name })
            .then(response => response.data)
            .then((res) => {
                // let data = typeProductsList.splice(0, typeProductsList.length);
                let data = [];
                // console.log('DATA => ', data)
                res.result.map((item) => data.push({ ...item, visibled: false }));

                resolve(data);
            })
            .catch((err) => {
                reject(err);
            })

    });
}

export const onProductsAdicionar = (data) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';

    return new Promise((resolve, rejetc) => {
        server.post('/add', { db_name, ...data })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err);
            })
    })
}

export const onProductsAddAcq = (data) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';

    return new Promise((resolve, rejetc) => {
        server.post('/acqProduct', { db_name, ...data })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err.reponse.data);
            })
    })
}

export const onProductsAddStoke = (data) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';

    return new Promise((resolve, rejetc) => {
        server.post('/saveStoke', { db_name, ...data })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err);
            })
    })
}

export const onProductsEntryAll = () => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';

    return new Promise((resolve, rejetc) => {
        server.post('/entryAll', { db_name })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err);
            })
    })
}

export const apiOnGetProducts = (codeBar) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';

    return new Promise((resolve, rejetc) => {
        server.post('/entry', { db_name, codeBar })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err);
            })
    })
}

export const onProductsEntrySave = (data) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';
    const id_user = JSON.parse(localStorage.getItem('user'))?.id || -1;

    return new Promise((resolve, rejetc) => {
        server.post('/entryProd', { db_name, last_iam: id_user, ...data })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err);
            })
    })
}

export const onProductsExitSave = (data) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';
    const id_user = JSON.parse(localStorage.getItem('user'))?.id || -1;

    return new Promise((resolve, rejetc) => {
        server.post('/exitProd', { db_name, last_iam: id_user, ...data })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err);
            })
    })
}

export const apiOnProductsEditeSave = (data) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';
    const id_user = JSON.parse(localStorage.getItem('user'))?.id || -1;

    return new Promise((resolve, rejetc) => {
        server.post('/updatedProduct', { db_name, last_iam: id_user, ...data })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err);
            })
    })
}

export const apiOnProductsDelete = (id) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';

    return new Promise((resolve, rejetc) => {
        server.post('/delete', { db_name, id })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                rejetc(err);
            })
    })
}

// export const onProductStokeSearch = (cod_product) => {
//     return new Promise((resolve, reject) => {

//         server.post('/stokeSearch', { cod_product })
//             .then(response => response.data)
//             .then((res) => {
//                 // let data = typeProductsListStoke.splice(0, typeProductsListStoke.length);
//                 let data = [];
//                 data = res.result;

//                 setTimeout(() => {
//                     resolve(data);
//                 }, 1200);
//             })
//             .catch((err) => {
//                 reject(err);
//             })

//     });
// }

export const onProductSearchSela = (codBar) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';
    
    return new Promise((resolve, reject) => {

        server.post('/search', { db_name, codBar })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                reject(err);
            })

    });
}

export const onProductExit = (data) => {
    return new Promise((resolve, reject) => {

        server.post('/exit', data)
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                reject(err);
            })

    });
}