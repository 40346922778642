import React, { useEffect } from 'react';
import styles from './styles.module.css';
import { Button } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import { local_db } from '../../../utils/local_db';
// import { useLiveQuery } from 'dexie-react-hooks';
import { onProductsList } from '../../../serverless/products';

const Sales = () => {


    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        // local_db.version(1).stores({
        //     products: `++id, list`
        // });

        try {
            const products = await onProductsList();
            await local_db?.products.clear();
            await local_db?.products.add(products);
        } catch (error) {
            alert('Sistema não atualizado')
        }
        // const i = useLiveQuery(() => local_db.products.toArray());
        // const i = await local_db?.products.toArray();
        // if (i.length > 1) await local_db?.products.delete(id-1);
        // console.log('ADICIONADO: ', id, i.length);
    }

    return (
        <div className={styles.container} >

            <div>
                <Link to={'/panel/pointsale'} state={{ isPre: false }} /*target='_blank'*/ >
                    <Button variant='contained' > Ponto de vendas </Button>
                </Link>

                <Link to={'/panel/pointsale'} state={{ isPre: true }} /*target='_blank'*/ >
                    <Button variant='contained' > Pré-Vendas </Button>
                </Link>

                <Link to={'/panel/pointsale'} /*target='_blank'*/ >
                    <Button variant='contained' > Orçamentos </Button>
                </Link>

                <Link to={'/panel/pointsale'} /*target='_blank'*/ >
                    <Button variant='contained' > Relatórios </Button>
                </Link>
            </div>

            <Outlet />
        </div>
    )
}

export default Sales;