import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Typography, Button, TextField } from '@mui/material';
import { apiOnList } from '../../../../serverless/customers';

const CustomersList = () => {

    const [state, setState] = useState({
        list: [{ id: 0, name: '', cpf: '', phone: '', email: '', dateBirth: '', limite: 0, invoice: 0, note: '', logIAM: '', created: '', updated: '' }],
    });

    let { list } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = () => {
        apiOnList().then((response) => {
            list = response;
            setState({ ...state, list });
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <div className={styles.container} >

            <div className={styles.search} >
                <TextField
                    type='search'
                    fullWidth
                    label='Buscar'
                    placeholder='Digite aqui'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 40 } }}
                />
            </div>

            <div className={styles.headerList} >
                <Typography> Cliente </Typography>
                <Typography> Limite </Typography>
                <Typography> Fatura </Typography>
                <Typography> Disponível </Typography>
                <Typography> Ações </Typography>
            </div>

            {list.map((customer, key) => (
                <div key={key} className={styles.customerList} >
                    <div>
                        <Typography> {customer.name} </Typography>
                        <Typography> {`R$ ${customer.limite}`} </Typography>
                        <Typography> {`R$ ${customer.invoice}`} </Typography>
                        <Typography> {`R$ ${(customer.limite - customer.invoice).toFixed(2)}`} </Typography>

                        <div>
                            <Button>Abrir</Button>
                            <Button>Editar</Button>
                        </div>
                    </div>

                </div>
            ))}

            {list.length === 0 ? <Typography> Não existe nenhum cliente</Typography> : null}

        </div>
    )
}

export default CustomersList;