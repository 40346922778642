import React from "react";
import styles from './styles.module.css';

const Suggestions = () => {

    return (
        <div className={styles.container} >
            suggestions
        </div>
    )
}

export default Suggestions;