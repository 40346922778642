
export const removeCaracters = (value) => {
    let inputValue = '';
    inputValue = value;
    inputValue = inputValue.replace(/[^\d\s-/]/g, "");
    return inputValue;
}

export const convertCurrency = (value) => {
    let inputValue = '';
    inputValue = value;
    // inputValue = inputValue.replace(/[^\d\s-/]/g, "");
    inputValue = inputValue.replace('.', '').replace(',', '.');
    
    const result = Number.parseFloat(inputValue);
    return result;
}