import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import { apiOnProductsDelete, onProductsList } from '../../../../serverless/products';
// import { typeProductBasic } from '../../../../interfaces/products';
import { Button, TextField, Typography, Collapse, CircularProgress } from '@mui/material';
import { BsSearch, BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { MdLaunch, MdOutlineDelete, MdEdit, MdOutlineCloudSync } from 'react-icons/md';
import { IoMdExit, IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { DialogConfirm } from '../../../../components/Dialogs';
import { useNavigate } from 'react-router-dom';
import { TypeProducts } from '../../../../interfaces/products';
import { local_db } from '../../../../utils/local_db';
// import { useLiveQuery } from 'dexie-react-hooks';


const ListProducts = () => {

    const navigate = useNavigate();

    const [state, setState] = useState({
        products: [new TypeProducts()],
        productAll: [new TypeProducts()],

        navCount: { pag: 1, count: 0 },
        isDelete: {},
    });

    let { products, productAll, navCount, isDelete } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        try {
            products.splice(0);
            productAll.splice(0);
            setState({ ...state });

            // product = await onProductsList();
            let arrProduct = await local_db?.products.toArray();
            products = Array.from(arrProduct[0]);
            // console.log(product)
            

            setState({ ...state, products, productAll: products });
            // await local_db?.products.clear();
            // await local_db.products.add(product);
        } catch (error) {
            console.log('-----')
            onCloudSync();
            // alert('Productos não atualizados')
        }
    }

    const onCloudSync = async () => {
        try {
            products.splice(0);
            productAll.splice(0);
            setState({ ...state });

            products = await onProductsList();

            setState({ ...state, products, productAll: products });
            await local_db?.products.clear();
            await local_db.products.add(products);
        } catch (error) {
            console.log('---------', error)
            // alert('Productos não atualizados')
        }
    }

    const onSearch = (value) => {
        if (products.length >= 1 && value.length >= 3) {
            products = productAll.filter(e =>
                e.codeBar.includes(value) ||
                e.name.toUpperCase().includes(value.toUpperCase()) ||
                e.name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(value.toUpperCase()));
        } else if (value.length <= 2) {
            products = productAll;
        }
        setState({ ...state, products });
    }

    const onNav = (key) => {
        if (key === 'next') {
            navCount.pag++;
            navCount.count += 8;
        } else {
            navCount.pag--;
            navCount.count -= 8;
        }
        setState({ ...state, navCount });
    }

    const onVisibled = (id, boolean) => {
        products.filter((e) => e.id === id)[0].visibled = boolean;
        setState({ ...state, products });
    }

    const onDeleteDialog = (product) => {

        // const conf = window.confirm(`Realmente deseja excluir esse item: ${product?.name} ? A ação não poderá de revertida.`);
        // console.log(conf);

        setState({ ...state, isDelete: product });
    }

    const onDeleteConfirm = (id) => {

        apiOnProductsDelete(id).then(async (response) => {
            // console.log(response);
            products = await onProductsList();
            setState({ ...state, isDelete: {}, products, productAll: products });
        }).catch((err) => {
            console.log(err);
            setState({ ...state, isDelete: {} })
            alert('Erro. Tente novamente.')
        })
    }

    return (
        <div className={styles.container} >

            <div className={styles.isLoading} style={{ display: products.length ? 'none' : 'flex' }} >
                <CircularProgress color='success' size={25} />
                <Typography> aguarde... </Typography>
            </div>

            <div className={styles.searchBar} style={{ display: products.length ? 'flex' : 'none' }} >

                <Button onClick={onCloudSync} title='Sincronizar Dados' >
                    <MdOutlineCloudSync size={30} />
                </Button>

                <TextField
                    type='search'
                    className={styles.searchText}
                    autoComplete='off'
                    label='Buscar'
                    placeholder='Digite'
                    onChange={(e) => onSearch(e.target.value)}
                    InputProps={{ style: { height: 40 } }}
                    InputLabelProps={{ shrink: true }}
                />
                <BsSearch color='royalblue' className={styles.searchIcon} />
            </div>

            <div className={styles.listHeader} style={{ display: products.length ? 'flex' : 'none' }} >
                <Typography className={styles.listHeaderTitle} > Código de Barra </Typography>
                <Typography className={styles.listHeaderTitle} > Produto </Typography>
                <Typography className={styles.listHeaderTitleRight} > Ações </Typography>
            </div>

            {products?.slice(navCount.count, navCount.count + 8)?.map((product, key) => (
                <div key={key} className={styles.listBox} >

                    <div className={styles.listRow} style={{ backgroundColor: product.visibled ? 'dodgerblue' : '' }} >
                        <Typography className={styles.listText} > {product.codeBar} </Typography>
                        <Typography className={styles.listText} > {product.name} </Typography>
                        <div>
                            <MdLaunch onClick={() => navigate('/panel/products/entry', { replace: true, state: product })} size={20} title='Entrada' />
                            <IoMdExit onClick={() => navigate('/panel/products/exit', { replace: true, state: product })} size={20} title='Saída' />
                            <MdEdit onClick={() => navigate('/panel/products/edite', { replace: true, state: product })} size={20} title='Editar' />
                            <HiOutlineDocumentReport onClick={() => navigate('/panel/products/report', { replace: true, state: product })} size={20} title='Relatório' />
                            <MdOutlineDelete onClick={() => onDeleteDialog(product)} size={20} title='Excluir' />
                            {product.visibled ? <IoIosArrowUp size={20} title='Fechar' onClick={() => onVisibled(product.id, false)} /> : <IoIosArrowDown size={20} title='Abrir' onClick={() => onVisibled(product.id, true)} />}
                        </div>
                    </div>

                    <Collapse className={styles.listCollapse} component={'div'} in={product.visibled} unmountOnExit >
                        <div>

                            <Typography > {product?.name} </Typography>
                            <Typography fontFamily={'Roboto'} > Criado: {moment(product.created).subtract(3, 'hours').format('DD/MM/YYYY')} </Typography>
                            <Typography > {product?.descripition.length <= 30 ? product.descripition : product.descripition.substring(0, 30).concat(' ...')} </Typography>
                            <Typography > qtde: {product?.qtde} </Typography>
                            <Typography > Valor: {product?.value} </Typography>
                            <Typography > Medida: {product?.measure} </Typography>
                            <Typography > {product?.note} </Typography>
                            <Typography > Atualizado: {moment(product?.updated).subtract(3, 'hours').format('DD/MM/YYYY hh:mm')} </Typography>
                            <Typography > Ativo: {product?.active} </Typography>

                        </div>
                    </Collapse>

                </div>
            ))}

            <Typography className={styles.totalItens} style={{ display: products.length ? 'flex' : 'none' }} > Total: {products.length} </Typography>

            <div className={styles.navigationBar} style={{ display: products.length > 8 ? 'flex' : 'none' }} >
                <Button
                    disabled={navCount.count === 0 ? true : false}
                    variant='contained'
                    onClick={() => onNav('back')}
                    startIcon={<BsArrowLeft />} > anterior </Button>

                <Typography> {navCount.pag + ' de ' + (products.length >= 8 ? (products.length / 8).toFixed(0) : 1)} </Typography>

                <Button
                    variant='contained'
                    disabled={navCount.pag === Number.parseInt((products.length / 6).toFixed(0)) ? true : false}
                    onClick={() => onNav('next')}
                    endIcon={<BsArrowRight />} > proximo </Button>
            </div>


            <DialogConfirm
                isOpen={!!isDelete?.id}
                onClose={() => setState({ ...state, isDelete: {} })}
                title={'Olá'}
                text={'Tem certeza que deseja excluir esse item: '}
                name={isDelete?.name}
                subText={'Esta ação não poderá de revertida.'}
                onConfirm={(e) => onDeleteConfirm(e)}
                id={isDelete?.id}
            />

        </div>
    )
}

export default ListProducts;