import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { onProductSearchSela } from '../../../../serverless/products';
import { TextField, Typography, Button, Dialog, DialogTitle } from '@mui/material';
import { CiShoppingBasket } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';
import { Logo32 } from '../../../../images';
import { useNavigate, useLocation } from 'react-router-dom';
import { local_db } from '../../../../utils/local_db';
import { TypeProducts } from '../../../../interfaces/products';

const ListShopping = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        arrProducts: [new TypeProducts()],
        codBar: '',
        subTotal: 0,
        deletProduct: -1,
        selectProduct: [new TypeProducts()],
    });

    let { arrProducts, codBar, subTotal, deletProduct, selectProduct } = state;

    useEffect(() => {
        onVerificList();
        console.log(location.state?.isPre)
        // eslint-disable-next-line
    }, []);

    const onVerificList = () => {
        arrProducts.splice(0);
        selectProduct.splice(0);
        const arr = JSON.parse(localStorage.getItem('arrProducts'));

        if (arr != null && arr.length >= 1) {
            subTotal = 0;
            arr.map((i) => subTotal += (Number.parseFloat(i.value) * i.qtde));
            setState({ ...state, arrProducts: arr, subTotal });
        } else {
            // console.log('NADA ---> ', arr)
            setState({ ...state });
        }
        // console.log('LOCAL: ', arr);
    }

    // Apenas caso o db local não exista
    const onGetProducts = async () => {
        let qtde = '1';
        let codSearch = codBar;
        if (codBar.includes('*')) {
            qtde = codBar.substring(0, codBar.indexOf('*'));
            codSearch = codBar.substring(codBar.indexOf('*')).replace('*', '');
        }

        let product = await onProductSearchSela(codSearch);
        console.log(product)

        if (product.length >= 2) {
            setState({ ...state, selectProduct: product });
        } else {

            if (product.length) {

                if (product[0].measure === 'KG') {
                    product[0].qtde = Number.parseFloat(qtde.replace(',', '.')).toFixed(3);
                } else {
                    product[0].qtde = Number.parseInt(qtde);
                }

                if (product[0].qtde >= Number.parseInt(qtde)) {
                    arrProducts.push(product[0]);

                    subTotal += product[0].value * product[0].qtde;

                    setState({ ...state, arrProducts, subTotal, codBar: '', deletProduct: -1 });
                    localStorage.setItem('arrProducts', JSON.stringify(arrProducts));
                    // console.log('arrProducts -> ', arrProducts, qtde, codSearch);
                    // console.log('arrProducts -> ', localStorage.getItem('arrProducts'));

                } else {
                    alert(`Produto sem estoque suficiente`)
                }

            } else {
                alert(`Produto não encontrado`)
            }

        }
    }

    const onGetProductsLocal = async () => {
        let qtde = '1';
        let codSearch = codBar;
        if (codBar.includes('*')) {
            qtde = codBar.substring(0, codBar.indexOf('*'));
            codSearch = codBar.substring(codBar.indexOf('*')).replace('*', '');
        }

        let arr = await local_db.products.toArray();

        if (arr) {

            let arrItens = Array.from(arr[0])
                .filter((ar) =>
                    ar?.codeBar === codSearch ||
                    ar?.name.toUpperCase().includes(codSearch.toUpperCase()) ||
                    ar?.name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(codSearch.toUpperCase()));

            // console.log(arrItens);

            if (arrItens.length >= 2) {
                setState({ ...state, selectProduct: arrItens });
            } else if (arrItens.length === 1) {
                const product = arrItens[0];

                if (product.measure === 'KG') {
                    product.qtde = Number.parseFloat(qtde.replace(',', '.')).toFixed(3);
                } else {
                    product.qtde = Number.parseInt(qtde);
                }

                // if (product.qtde >= Number.parseInt(qtde)) {
                    arrProducts.unshift(product);

                    subTotal += product.value * product.qtde;

                    setState({ ...state, arrProducts, subTotal, codBar: '', deletProduct: -1 });
                    localStorage.setItem('arrProducts', JSON.stringify(arrProducts));

                // } else {
                //     alert(`Produto sem estoque suficiente.`)
                // }
            } else {
                alert('Produto não encontrado.')
            }
        } else {
            onGetProducts();
        }

    }

    const onRemoveItem = () => {
        if (arrProducts[deletProduct].measure === 'KG') {

            subTotal -= Number.parseFloat(arrProducts[deletProduct].value) * Number.parseFloat(arrProducts[deletProduct].qtde);
        } else {
            subTotal -= Number.parseFloat(arrProducts[deletProduct].value) * Number.parseInt(arrProducts[deletProduct].qtde);
        }
        arrProducts.splice(deletProduct, 1);
        setState({ ...state, arrProducts, subTotal, deletProduct: -1 });
        if (arrProducts.length === 0) {
            localStorage.removeItem('arrProducts');
        } else {
            localStorage.setItem('arrProducts', JSON.stringify(arrProducts));
        }
    }

    const onRemoveItemAll = () => {
        subTotal = 0;
        arrProducts.splice(0);
        localStorage.removeItem('arrProducts');
        setState({ ...state, arrProducts, subTotal, deletProduct: -1 });
    }

    const onPayment = () => {
        navigate('payment', { state: arrProducts });
    }

    const ModalSelectItem = () => {

        const onConfirmItem = (item) => {

            let qtdeInput = '1';
            if (codBar.includes('*')) {
                qtdeInput = codBar.substring(0, codBar.indexOf('*'));
            }

            if (item?.measure === 'KG') {
                item.qtde = Number.parseFloat(qtdeInput.replace(',', '.')).toFixed(3);
            } else {
                item.qtde = Number.parseInt(qtdeInput);
            }

            if (item?.qtde >= Number.parseInt(qtdeInput)) {

                arrProducts.unshift(item);

                subTotal += item?.value * item?.qtde;

                setState({ ...state, arrProducts, subTotal, codBar: '', selectProduct: [] });
                localStorage.setItem('arrProducts', JSON.stringify(arrProducts));

            } else {
                alert(`Produto sem estoque suficiente`)
            }

        }

        return (
            <Dialog open={selectProduct?.length ? true : false || false} onClose={() => setState({ ...state, selectProduct: [] })} >
                <DialogTitle> Selecione </DialogTitle>
                {selectProduct.map((item, key) => (
                    <div key={key} className={styles.dialog} style={{ backgroundColor: key % 2 === 0 ? 'silver' : 'whitesmoke' }} >
                        <Button onClick={() => onConfirmItem(item)} > {item?.name} - {item?.measure} - <b> R$ {item?.value} </b> </Button>
                    </div>
                ))}
            </Dialog>
        )
    }

    return (
        <div className={styles.container} >
            <TextField
                className={styles.inputCode}
                autoFocus
                label='Codigo do Item'
                autoComplete='off'
                placeholder='Digite o codigo'
                helperText='use * para quantidades'
                value={codBar}
                onChange={(e) => setState({ ...state, codBar: e.target.value })}
                onKeyDown={(e) => (e.ctrlKey && e.key === 'Enter' && arrProducts.length) ? onPayment() : e.key === 'Enter' ? onGetProductsLocal() : {}}
            // onKeyDown={(e) => alert(e.key)}
            />

            <ModalSelectItem />

            <div className={styles.cardCenter} >

                <div className={styles.cardLeft} >
                    <CiShoppingBasket className={styles.cardLeftIcon} color='dodgerblue' size={150} />
                    <img src={Logo32} alt="Logo32" />
                    <Typography> Syntecn </Typography>
                    <Typography> SubTotal: R$ {subTotal.toFixed(2)} </Typography>
                </div>

                <div className={styles.cardRight} >
                    {arrProducts.map((item, key) => (
                        <div key={key} className={styles.cardItem} >
                            <div
                                onClick={() => setState({ ...state, deletProduct: deletProduct === key ? -1 : key })}
                                style={{ backgroundColor: deletProduct === key ? 'tomato' : '' }}
                                tabIndex={0}
                                onKeyDown={(e) => e.key === 'Delete' || e.key === 'Backspace' ? onRemoveItem() : {}} >
                                <Typography> {key + 1} </Typography>
                                <Typography> {item.name} </Typography>
                                <Typography> {`${item.qtde} x ${item.value}`} </Typography>
                                <Typography> {(item.qtde * Number.parseFloat(item.value)).toFixed(2)} </Typography>
                            </div>
                            {deletProduct === key ? <MdDelete size={20} onClick={onRemoveItem} /> : null}
                        </div>
                    ))}
                </div>

            </div>

            <div>
                <Button className={styles.buttonPayment} variant='contained' onClick={onPayment} disabled={!!!arrProducts.length} >Pagamento</Button>
            </div>

            <Button className={styles.buttonClean} title='Limpar lista de produtos' disabled={arrProducts.length ? false : true} onClick={onRemoveItemAll} >limpar</Button>

        </div>
    )
}

export default ListShopping;