
import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { Button, MenuItem, TextField, Typography, Collapse } from '@mui/material';
// import { onBrandList } from '../../../../serverless/brand';
// import { onProvidersList } from '../../../../serverless/providers';
import { onProductsAdicionar, onProductsAddAcq } from '../../../../serverless/products';
import { onFormatCurrent } from '../../../../utils/currenty';
import { convertCurrency } from '../../../../utils/NumberFormt';
import { BsCheck2, BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { useApplicationContext } from '../../../../context/application';
import { TypeProducts } from '../../../../interfaces/products';

const AddProduct = () => {

    const { id: user_id } = useApplicationContext();

    const [state, setState] = useState({
        product: new TypeProducts(),
        // visiblid
        isProduct: true,
        isStoke: true,
    });

    let { product, isProduct, isStoke } = state;
    let { name, descripition, codeBar, cost, qtde, value, measure, note } = product;

    const optsmeasure = [
        { label: 'UN', value: 'UN' },
        { label: 'KG', value: 'KG' },
    ]
    const optsBrand = [
        { label: 'Colcci', value: 'Colcci' },
        { label: 'KG', value: 'KG' },
    ]
    const optsSupplier = [
        { label: 'UN', value: 'UN' },
        { label: 'KG', value: 'KG' },
    ]

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        // listFornecedor = await onProvidersList();
        // listFornecedor.unshift({ id: 0, nome: '', nome_social: '', endereco: '' })
        // listMarca = await onBrandList();
        // listMarca.unshift({ id: 0, nome: '', created: '' })
        // setState({ ...state, listFornecedor, listMarca });
        // console.log('ON INIT')
        product.measure = 'UN';
        setState({ ...state, product });
    }

    const onChangeState = (key, inputValue) => {

        if (key === 'cost') {
            product[key] = onFormatCurrent(inputValue);
        } else if (key === 'supplier') {
            console.log('supplier');
        } else if (key === 'value') {
            product[key] = onFormatCurrent(inputValue);
        } else {
            product[key] = inputValue;
        }
        setState({ ...state, product });

    }

    const onSaveEstoque = (product_id) => {

        if (measure === '' || qtde === '' || value === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            alert(`Produto registrado com sucesso.\n
            Acesse ENTRADA DE PRODUTOS para adiconar estoque!`);
            onClean();
        } else {

            const data = { idRef: product_id, typeAcquisition: 'product', amount: qtde, cost: convertCurrency(cost), costTotal: (qtde * convertCurrency(cost)) };

            onProductsAddAcq(data)
                .then((res) => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    alert(`Produto registrado com sucesso.`)
                    onClean();
                }).catch((err) => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    alert(`Produto registrado com sucesso.\n
            Porém não conseguimos aplicar o dados da AQUISIÇÃO\n
            considerar excluir e repetir a operação ou solicite suporte.`);
                    onClean();
                })
        }

    }

    const onSaveProduct = () => {

        if (codeBar === '') {
            alert('Preencha todos os campos com *.\n*Código de Barra*');
        } else if (name === '') {
            alert('Preencha todos os campos com *.\n*Nome do Produto*');
        } else {

            let data = product;
            data.value = value === '' ? 0 : convertCurrency(value);
            data.last_iam = user_id;
            // fazer com o custo tbm

            onProductsAdicionar(data).then((res) => {

                console.log('RES: ', res.insertId);
                onSaveEstoque(res.insertId);

            }).catch((err) => {
                console.log('ERRO: ', err.response.data.err.code);
                onErr(err.response.data.err.code);
            });
        }
    }

    const onClean = () => {
        product = new TypeProducts();
        setState({ ...state, product });
    }

    const onIsFormProduct = () => {
        setState({ ...state, isProduct: !isProduct });
    }

    const onIsFormStock = () => {
        setState({ ...state, isStoke: !isStoke });
    }

    const onErr = (type) => {
        if (type === 'ER_DUP_ENTRY') {
            alert('Produto já existente!');
        } else {
            alert('Não foi possível resgistrar esse produto.\nTente novamente\nOu contate o adminstrado do sistema.')
        }
    }

    return (
        <div className={styles.container} >

            <div className={styles.rowTitles} onClick={onIsFormProduct} >
                <Typography className={styles.title} >Cadastro de Produtos</Typography>
                {isProduct ? <BsChevronUp size={25} onClick={onIsFormProduct} /> : <BsChevronDown size={25} onClick={onIsFormProduct} />}
            </div>

            <Collapse in={isProduct} unmountOnExit component={'div'} className={styles.card} >

                <div className={styles.rowBox} >
                    <TextField
                        autoComplete='off'
                        autoFocus
                        className={styles.rowInput}
                        InputLabelProps={{ shrink: true }}
                        type='text'
                        onChange={(e) => e.target.value.length <= 20 ? onChangeState('codeBar', e.currentTarget.value) : {}}
                        value={codeBar}
                        fullWidth
                        placeholder='Digite aqui'
                        label='Código de Barra' />

                    <TextField
                        autoComplete='off'
                        className={styles.rowInput}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ style: { textTransform: 'capitalize' } }}
                        onChange={(e) => onChangeState('name', e.currentTarget.value)}
                        value={name}
                        placeholder='Digite aqui'
                        fullWidth label='Nome' />

                </div>

                <div className={styles.rowBox} >

                    <TextField
                        autoComplete='off'
                        className={styles.rowInput}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ style: { textTransform: 'capitalize' } }}
                        onChange={(e) => onChangeState('descripition', e.currentTarget.value)}
                        value={descripition}
                        fullWidth
                        placeholder='Digite aqui'
                        label='Descrição' />

                    <TextField
                        select
                        disabled
                        className={styles.rowInput}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => onChangeState('brand', e.target.value)}
                        value={optsBrand[0].value}
                        fullWidth
                        placeholder='Selecione'
                        label='Marca' >
                        {optsBrand.map((item, key) => <MenuItem key={key} value={item.value} > {item.label} </MenuItem>)}
                    </TextField>



                </div>

                <div className={styles.rowBox} >
                    <TextField
                        autoComplete='off'
                        className={styles.rowInput}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ style: { textTransform: 'capitalize' } }}
                        onChange={(e) => onChangeState('note', e.currentTarget.value)}
                        value={note}
                        fullWidth
                        placeholder='Digite aqui'
                        label='Observação' />
                </div>

            </Collapse>



            <div className={styles.rowTitles} onClick={onIsFormStock} >
                <Typography className={styles.title} > Dados de Estoque </Typography>
                {isStoke ? <BsChevronUp size={25} onClick={onIsFormStock} /> : <BsChevronDown size={25} onClick={onIsFormStock} />}
            </div>

            <Collapse in={isStoke} unmountOnExit component={'div'} className={styles.card} >

                <div className={styles.rowBox} >
                    <TextField
                        select
                        className={styles.rowInput}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => onChangeState('measure', e.target.value)}
                        defaultValue={'UN'}
                        value={measure}
                        fullWidth
                        label='Unidade de Medida'
                    >
                        {optsmeasure.map((item, key) => <MenuItem key={key} value={item.value} > {item.label} </MenuItem>)}
                    </TextField>

                    <TextField
                        type='number'
                        className={styles.rowInput}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => onChangeState('qtde', e.currentTarget.value)}
                        value={qtde}
                        fullWidth
                        label='Quantidade de itens'
                        placeholder='0'
                    />

                </div>

                <div className={styles.rowBox} >

                    <TextField
                        type='text'
                        className={styles.rowInput}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => e.currentTarget.value.length <= 9 ? onChangeState('cost', e.currentTarget.value) : {}}
                        value={cost}
                        defaultValue={'0'}
                        fullWidth
                        placeholder='R$'
                        label='Custo por item' />

                    <TextField
                        type={'text'}
                        className={styles.rowInput}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => e.currentTarget.value.length <= 9 ? onChangeState('value', e.currentTarget.value) : {}}
                        value={value}
                        fullWidth
                        label='Valor de venda'
                        placeholder='R$'
                    />
                </div>

                <div className={styles.rowBox} >
                    <TextField
                        select
                        disabled
                        autoComplete='off'
                        className={styles.rowInput}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => onChangeState('supplier', e.target.value)}
                        value={optsSupplier[0].value}
                        fullWidth
                        placeholder='Selecione'
                        label='Fornecedor' >
                        {optsSupplier.map((item, key) => <MenuItem key={key} value={item.value} > {item.label} </MenuItem>)}
                    </TextField>

                </div>

            </Collapse>



            <div className={styles.rowBTs} >

                <Button
                    className={styles.saveBT}
                    variant='contained'
                    color='warning'
                    onClick={onClean}
                >
                    limpar campos
                </Button>

                <Button
                    className={styles.saveBT}
                    variant='contained'
                    color='success'
                    onClick={onSaveProduct}
                    endIcon={<BsCheck2 color='white' />}
                >
                    salvar registro
                </Button>

            </div>

        </div>
    )
}

export default AddProduct;