import React from 'react';
import styles from './styles.module.css';

const Reports = () => {
    return (
        <div className={styles.container} >
            reports
        </div>
    )
}

export default Reports;