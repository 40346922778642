
export const onFormatCurrent = (value) => {
    let inputValue = '';
    inputValue = value;
    inputValue = inputValue.replace(/[^\d\s-/]/g, "");
    inputValue = inputValue.replace('.', '').replace(',', '');

    if (inputValue.length === 2) {
        inputValue = inputValue.substring(0, 1).concat(',') + inputValue.charAt(1);

    } else if (inputValue.length === 3) {
        inputValue = inputValue.substring(0, 1).concat(',') + inputValue.substring(1);

    } else if (inputValue.length === 4) {
        inputValue = inputValue.substring(0, 2).concat(',') + inputValue.substring(2);
    
    } else if (inputValue.length === 5) {
        inputValue = inputValue.substring(0, 3).concat(',') + inputValue.substring(3);
    
    } else if (inputValue.length === 6) {
        inputValue = inputValue.substring(0, 1).concat('.') + inputValue.substring(1,4).concat(',') + inputValue.substring(4);
    
    } else if (inputValue.length === 7) {
        inputValue = inputValue.substring(0, 2).concat('.') + inputValue.substring(2,5).concat(',') + inputValue.substring(5);
    }


    return inputValue;
}
