import { serverCustomers as server } from '../server';

export const apiOnSave = (data) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';

    return new Promise((resolve, reject) => {
        server.post('/add', { db_name, ...data })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            }).catch((err) => {
                reject(err);
            });
    })
}

export const apiOnList = () => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';

    return new Promise((resolve, reject) => {
        server.post('/list', { db_name })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            }).catch((err) => {
                reject(err);
            });
    })
}

export const apiOnClient = (CPF_NAME) => {
    const db_name = JSON.parse(localStorage.getItem('user'))?.db_name || 'syntecn_db';

    return new Promise((resolve, reject) => {
        server.post('/getClient', { db_name, CPF_NAME })
            .then(response => response.data)
            .then((res) => {
                resolve(res.result);
            }).catch((err) => {
                reject(err);
            });
    })
}