import React from 'react';
import styles from './styles.module.css';
import { Button, Typography } from '@mui/material';
import { BsList, BsPlus } from 'react-icons/bs';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

const Customers = () => {

    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <div className={styles.container} >

            <div className={styles.barNav} >
                <Button
                    variant={pathname.includes('add') || pathname.includes('report') ? 'contained' : 'outlined'}
                    onClick={() => navigate('/panel/customers', { replace: true })}
                >
                    <Typography> Lista </Typography>
                    <BsList size={20} />
                </Button>

                <Button
                    variant={pathname.includes('add') ? 'outlined' : 'contained'}
                    onClick={() => navigate('add', { replace: true })}
                >
                    <Typography> Adicionar </Typography>
                    <BsPlus size={20} />
                </Button>

                <Button
                    variant={pathname.includes('report') ? 'outlined' : 'contained'}
                    onClick={() => navigate('report', { replace: true })}
                >
                    <Typography> Relatório </Typography>
                    <HiOutlineDocumentReport size={20} />
                </Button>
            </div>

            <Outlet />
        </div>
    )
}

export default Customers;