import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { BsCheck2 } from 'react-icons/bs';

const MobilePayment = () => {

    const navigate = useNavigate();

    const [state, setState] = useState({
        isProgress: 0,
    });

    let { isProgress } = state;

    useEffect(() => {
        setTimeout(() => {
            setState({ ...state, isProgress: 1 });
            setTimeout(() => {
                navigate('/sales/pointsales', { replace: true });
            }, 1500);
        }, 1500);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.container} >
            Mobile Payment
            {isProgress === 0 ? <CircularProgress size={50} /> : <BsCheck2 size={50} color='seagreen' />}
        </div>
    )
}

export default MobilePayment;