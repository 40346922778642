import React from 'react';
import styles from './styles.module.css';

const ReportProducts = () => {
    return (
        <div className={styles.container} >
            Relatório de Produtos
        </div>
    )
}

export default ReportProducts;