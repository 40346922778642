import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { Button, Drawer, Typography } from '@mui/material';
import { AiOutlineHome } from 'react-icons/ai';
// import { RiCustomerService2Line } from 'react-icons/ri';
import { FaUserTag } from 'react-icons/fa';
import { MdPointOfSale } from 'react-icons/md';
import { BsBoxSeam } from 'react-icons/bs';
import { useNavigate, useLocation } from 'react-router-dom';
import { Logo32 } from '../../../images';

const MobileNavigateDrawer = ({ drawerVisibled, onVisibled }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        options: [
            { id: 0, title: 'Painel', pathname: '/panel', select: false, icon: <AiOutlineHome className={styles.optionsIcon} size={30} /> },
            { id: 1, title: 'Produtos', pathname: '/products', select: false, icon: <BsBoxSeam className={styles.optionsIcon} size={30} /> },
            { id: 2, title: 'Clientes', pathname: '/customers', select: false, icon: <FaUserTag className={styles.optionsIcon} size={30} /> },
            { id: 3, title: 'Vendas', pathname: '/sales', select: false, icon: <MdPointOfSale className={styles.optionsIcon} size={30} /> },
        ]
    });

    let { options } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, [location.pathname])

    const onInit = () => {

        options.map((opt) => {
            if (location.pathname === opt.pathname) {
                opt.select = true;

            } else if (location.pathname.includes(opt.pathname) && opt.pathname !== '/panel') {
                opt.select = true;

            } else {
                opt.select = false;
            }
            return opt;
        });
        setState({ ...state, options });
    }

    const onNavigate = (path) => {

        onVisibled();
        navigate(path, { replace: true });

    }


    return (
        <div className={styles.container} >
            <Drawer
                anchor={'left'}
                open={drawerVisibled}
                onClose={onVisibled} >

                <div className={styles.drawerCard} >
                    <img src={Logo32} alt="Logo blue" />
                    <Typography className={styles.title} >Syntecn</Typography>

                    <hr />

                    {options.map((item, key) => (
                        <div key={key} className={styles.optionsCard} >
                            {item.icon}
                            <Button
                                className={item.select ? styles.optionsBTselect : styles.optionsBT}
                                onClick={() => onNavigate(item.pathname)} >
                                {item.title}
                            </Button>
                        </div>
                    ))}
                </div>

            </Drawer>
        </div>
    )
}

export default MobileNavigateDrawer;