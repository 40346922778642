import React, { useState } from 'react';
import styles from './styles.module.css';
import MobileHeaderBar from '../../components/HeaderBar';
import MobileNavigateDrawer from '../../components/NavigateDrawer';
import { Button, Collapse, Typography } from '@mui/material';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const MobilePanel = () => {

    const navigate = useNavigate();

    const [state, setState] = useState({
        drawerVisibled: false,
        isVisibled: { sales: false, products: false }
    });

    let { drawerVisibled, isVisibled } = state;

    const arrLinks = [
        { title: 'Produtos', to: '/products' },
        { title: 'Clientes', to: '/customers' },
        { title: 'Vendas', to: '/sales' },
    ]

    const onVisibled = () => {
        setState({ ...state, drawerVisibled: !drawerVisibled });
    }

    const onVisibledPreview = (title) => {
        isVisibled[title] = !isVisibled[title];
        setState({ ...state, isVisibled });
    }

    return (
        <div className={styles.container}>
            <MobileHeaderBar onVisibled={onVisibled} />
            <MobileNavigateDrawer drawerVisibled={drawerVisibled} onVisibled={onVisibled} />

            <div className={styles.boxSales} >
                <div className={styles.boxSalesHeader} >
                    <Button onClick={() => onVisibledPreview('sales')} > STATUS VENDAS </Button>
                    {isVisibled.sales ? <HiChevronUp size={30} /> : <HiChevronDown size={30} />}
                </div>
                
                <Collapse className={styles.boxSalesCollapse} in={isVisibled.sales} unmountOnExit >

                    <Typography> Hoje: R$ 120 </Typography>
                    <Typography> Quant.: 12 </Typography>

                    <Typography> Semana: R$ 640 </Typography>
                    <Typography> Quant.: 22 </Typography>

                    <Typography> Mês: R$ 4200 </Typography>
                    <Typography> Quant.: 55 </Typography>

                </Collapse>
            </div>

            {arrLinks.map((i, key) => (
                <Button key={key} className={styles.btLink} variant='outlined' onClick={() => navigate(i.to)} > {i.title} </Button>
            ))}

        </div>
    )
}

export default MobilePanel;