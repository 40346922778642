import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { apiOnGetProducts, onProductsEntrySave } from '../../../../serverless/products';
import { TypeProducts } from '../../../../interfaces/products';
import { TextField, Typography, Button } from '@mui/material';
import { onFormatCurrent } from '../../../../utils/currenty';
import { useLocation } from 'react-router-dom';

const EntryProducts = () => {

    const location = useLocation();

    const [state, setState] = useState({
        productEntry: new TypeProducts(),
        valuesEntry: { qtde: '', cost: '', supplier: 0 },
        codeBarInput: '',
        heightInput: 45,
        btDisabled: false,
    });

    let { productEntry, codeBarInput, heightInput, valuesEntry, btDisabled } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = () => {
        // onProductsEntryAll().then((res) => {
        //     listSearch = res;
        //     setState({ ...state, listSearch });
        // }).catch((err) => {
        //     alert('ERR REQUEST');
        // });
        // onEntryGet(location.state);
        const stateProduct = location.state;
        if (stateProduct) {
            setState({ ...state, productEntry: stateProduct });
        }
    }

    const onStateEntry = (key, value) => {
        if (key === 0) {
            valuesEntry.qtde = value;

        } else if (key === 1) {
            valuesEntry.cost = onFormatCurrent(value);

        } else if (key === 2) {
            // valuesEntry.supplier = value;

        }

        setState({ ...state, valuesEntry });
    }

    const onEntryGet = (codeBar) => {
        apiOnGetProducts(codeBar).then((response) => {
            if (response.length) {
                productEntry = response[0];
            } else {
                alert('Produto não encontrado');
            }
            // codeBarInput = productEntry.codeBar;
            setState({ ...state, productEntry, codeBarInput: '' });
        }).catch((err) => {
            console.log('ERRO: ', err);
            alert('Não foi possível buscar o produto.');
        });
    }

    const onInputCodeBar = (codeBar) => {
        setState({ ...state, codeBarInput: codeBar });
    }

    const onSave = () => {
        if (valuesEntry?.qtde <= 0) {
            alert(`Quantidade precisa ser maior que 0.`);
        } else if (Number.parseFloat(valuesEntry?.cost) < 0) {
            alert(`Custo não pode ser negativo.`);
        } else {
            setState({ ...state, btDisabled: true });
            const dataSave = { codeBar: productEntry.codeBar, qtde: valuesEntry?.qtde };

            onProductsEntrySave(dataSave).then(() => {
                // console.log(response)
                alert('tudo certo');
                productEntry.qtde += Number.parseInt(valuesEntry.qtde);
                valuesEntry.qtde = '';
                setState({ ...state, productEntry, valuesEntry, btDisabled: false });
            }).catch((err) => {
                setState({ ...state, btDisabled: false });
            })
        }
    }

    const onClean = () => {
        valuesEntry.qtde = '';
        setState({ ...state, productEntry: new TypeProducts(), valuesEntry });
    }

    return (
        <div className={styles.container} >

            <Typography className={styles.title} > Entrada de Produtos </Typography>

            <div className={styles.rowBox} >

                <TextField
                    type={'search'}
                    className={styles.inputs}
                    autoComplete='off'
                    label='Código Produto'
                    InputProps={{ style: { height: heightInput } }}
                    InputLabelProps={{ shrink: true }}
                    value={codeBarInput}
                    onKeyDown={(e) => e.key === 'Enter' ? onEntryGet(codeBarInput) : {}}
                    onChange={(e) => onInputCodeBar(e.target.value)}
                />

            </div>

            <div className={styles.rowDescripion} >
                <Typography > {productEntry?.name || '--------------'} </Typography>
                <Typography > {productEntry.codeBar ? `Código: ` : ''} <b>{productEntry?.codeBar}</b> </Typography>
                <Typography > {productEntry.qtde ? `Estoque:` : ''} <b>{productEntry?.qtde}</b> </Typography>
            </div>

            <div className={styles.rowBox} style={{ marginTop: '6vh' }} >
                <TextField
                    className={styles.inputs}
                    type='number'
                    autoComplete='off'
                    placeholder='0'
                    label='Quantidade'
                    InputProps={{ style: { height: heightInput } }}
                    InputLabelProps={{ shrink: true }}
                    value={valuesEntry?.qtde}
                    onChange={(e) => e.target.value.length < 10 ? onStateEntry(0, e.target.value) : {}}
                />

                <TextField
                    className={styles.inputs}
                    disabled
                    placeholder='R$ 0'
                    autoComplete='off'
                    label='Custo Unitário'
                    InputProps={{ style: { height: heightInput } }}
                    InputLabelProps={{ shrink: true }}
                    value={valuesEntry?.cost}
                    onChange={(e) => e.target.value.length < 10 ? onStateEntry(1, e.target.value) : {}}
                />

                <TextField
                    className={styles.inputs}
                    disabled
                    autoComplete='off'
                    placeholder='Opcional'
                    label='Fornecedor'
                    InputProps={{ style: { height: heightInput } }}
                    InputLabelProps={{ shrink: true }}
                    // value={valuesEntry?.supplier}
                    onChange={(e) => onStateEntry(2, e.target.value)}
                />
            </div>

            <Button
                className={styles.addBT}
                disabled={btDisabled ? true : (productEntry?.id > 0 && valuesEntry?.qtde !== '') ? false : true}
                variant='contained'
                onClick={onSave}
            >Aplicar</Button>

            <Button
                className={styles.addBT}
                disabled={btDisabled}
                variant='contained'
                color='warning'
                onClick={onClean}
            >Limpar</Button>

        </div>
    )
}

export default EntryProducts;