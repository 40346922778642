
import { serverUser as server } from '../server';
import Cookies from 'js-cookie';

export const onCheckin = (userCPF, password) => {
    return new Promise((resolve, reject) => {
        const dataPost = { userCPF, password };
        server.post('/auth', dataPost)
            .then((response) => response.data)
            .then((res) => {
                // console.log('RES -> ', res.result);
                resolve(res.result)
            }).catch((err) => {
                // console.log('ERR -> ', err);
                // reject(err.response.data.err)
                reject(err)
            })
    });
}

export const onLogged = () => {
    return new Promise(async (resolve, reject) => {
        let data = await JSON.parse(localStorage.getItem('user'));

        // console.log(data);
        resolve(data);
    });
}

export const apiOnCheckToken = () => {
    return new Promise((resolve, reject) => {
        const token = Cookies.get('st-token')
        server.post('/authCheck', { token })
            .then((response) => response.data)
            .then((res) => {
                // console.log('RES -> ', res.result);
                resolve(res.result)
            }).catch((err) => {
                console.log('ERR -> ', err);
                reject(err.response.data.err)
            })
    });
}