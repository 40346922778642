import { Routes, Route } from "react-router-dom";
import Login from './pages/Login';
import Home from "./pages/Home";
import Panel from "./pages/Panel";
// import Splash from './pages/Splash';
import Status from './pages/Status';
import Products from './pages/Products';
import Sales from "./pages/Sales";
import PointSale from './pages/PointSale';
import Customers from './pages/Customers';
import Suggestions from './pages/Suggestions'
import Services from './pages/Services';
import Reports from './pages/Reports';
import Settings from './pages/Settings';

/** VIEWS Products */
import ListProducts from "./views/products/ListProducts";
import AddProduct from "./views/products/AddProducts";
import EntryProducts from "./views/products/EntryProducts";
import ExitProducts from "./views/products/ExitProducts";
import EditeProducts from "./views/products/EditeProducts";
import ReportProducts from "./views/products/ReportProducts";

/** VIEWS Customers */
import CustomersList from "./views/customers/List";
import CustomersAdd from "./views/customers/Add";
import CustomersReport from "./views/customers/Report";

/** VIEWS Sales */
// import ListSales from "./views/sales/List";
import Day from "./views/sales/Day";
import SalesStatus from "./views/sales/Status";

/** VIEWS Point Sale */
import ListShopping from "./views/pointSale/ListShopping";
import Payment from './views/pointSale/Payment';
import FinishShopping from './views/pointSale/finish';

/** VIEWS Config */
import Synchronize from "./views/settings/Sync";


const Router = () => {
    return (
        <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/panel" element={<Panel />}>
                <Route index element={<Status />} />
                {/* <Route index element={<Splash />} /> */}
                <Route path="products" element={<Products />} >
                    <Route index element={<ListProducts />} />
                    <Route index path="add" element={<AddProduct />} />
                    <Route index path="entry" element={<EntryProducts />} />
                    <Route index path="exit" element={<ExitProducts />} />
                    <Route index path="edite" element={<EditeProducts />} />
                    <Route index path="report" element={<ReportProducts />} />
                </Route>

                <Route path="sales" element={<Sales />} >
                    <Route index element={<SalesStatus />} />
                    <Route path="day" element={<Day />} />
                    {/* <Route index element={<ListSales />} /> */}
                </Route>

                <Route path="pointsale" element={<PointSale />} >
                    <Route index element={<ListShopping />} />
                    <Route path="payment" element={<Payment />} />
                    <Route path="finish" element={<FinishShopping />} />
                </Route>
                
                <Route path="customers" element={<Customers />} >
                    <Route index element={<CustomersList />} />
                    <Route path="add" element={<CustomersAdd />} />
                    <Route path="report" element={<CustomersReport />} />

                </Route>

                <Route path="suggestions" element={<Suggestions />} />

                <Route path="services" element={<Services />} />
                <Route path="reports" element={<Reports />} />
                <Route path="settings" element={<Settings />} >
                    <Route index element={<Synchronize />} />
                </Route>

            </Route>

        </Routes>
    )
}

export default Router;