import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { CircularProgress } from '@mui/material';
import { BsCheck2 } from 'react-icons/bs';
import { useNavigate, useLocation } from 'react-router-dom';

const FinishShopping = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({ progess: 0 });

    useEffect(() => {
        setTimeout(() => {
            setState({ progess: 1 });
            setTimeout(() => {
                navigate('/panel/pointsale', { replace: true });
            }, 1000);
        }, 1000);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.container} >
            {state.progess === 0 ? <CircularProgress size={50} /> : <BsCheck2 color='seagreen' size={50} />}
            <b> Finalizando: {location.state.status} </b>
        </div>
    )
}

export default FinishShopping;