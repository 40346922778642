import Dexie from "dexie";

export const local_db = new Dexie('local_db');

local_db.version(1).stores({
    products: `++id`
});

// Dexie.exists('local_db').then((exist) => {
    
//     if (!exist) {   
//     }
// });


