import React, { useState } from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import { useApplicationContext } from '../../../context/application';
import { Alert, CircularProgress, Snackbar, Typography } from '@mui/material';
import { AiOutlineCloudSync, AiOutlineHome } from "react-icons/ai";
import { Outlet } from 'react-router-dom';
import { onProductExit, onProductsList } from '../../../serverless/products';
import { local_db } from '../../../utils/local_db';
import { apiOnSaleAdd } from '../../../serverless/sales';
import { useNavigate } from 'react-router-dom';

const PointSale = () => {

    const navigate = useNavigate();

    const [state, setState] = useState({ isSync: false, messeger: '' });

    const onSync = async () => {
        setState({ ...state, isSync: true });

        try {
            // SYNC SALES
            const sale = JSON.parse(localStorage.getItem('sale'));
            const lessProductsDB = JSON.parse(localStorage.getItem('lessProducts'));
            if (sale) {
                await Promise.all(sale.map(async (iten) => apiOnSaleAdd(iten).catch(() => console.log('SALE NÃO ENVIADO'))));
                localStorage.removeItem('sale');
            }
            if (lessProductsDB) {
                await Promise.all(lessProductsDB.map(async (iten) => await onProductExit(iten).catch(() => console.log(`LESS PROD NÃO ENVIADO`))));
                localStorage.removeItem('lessProducts');
            }
            // SYNC PRODUCTS
            const products = await onProductsList();
            await local_db?.products.clear();
            await local_db?.products.add(products);
            // const id = await local_db?.products.add(products);

            // const i = await local_db?.products.toArray();
            // if (i.length > 1) await local_db?.products.delete(id - 1);

            setTimeout(() => {
                setState({ ...state, isSync: false, messeger: 'Lista de Produtos atualizada.' });
            }, 1500);

        } catch (error) {
            setState({ ...state, isSync: false, messeger: '' });
            alert('Ocorreu um problema. Tente atualizar novamente mais tarde.')
        }

    }

    const { userName } = useApplicationContext();

    return (
        <div className={styles.container} >
            <span className={styles.headerUser} >
                <AiOutlineHome size={25} onClick={() => navigate(-1, { replace: true })} />
                <Typography> Caixa {moment().format('ll')} - {userName} </Typography>
                {state.isSync ? <CircularProgress size={20} color='success' style={{ marginRight: '3vw' }} /> : <AiOutlineCloudSync className={styles.icon} title='Atualizar' size={30} onClick={onSync} />}
            </span>

            <Snackbar
                open={state.messeger ? true : false}
                autoHideDuration={3000}
                onClose={() => setState({ ...state, messeger: '' })}
            >
                <Alert onClose={() => setState({ ...state, messeger: '' })} severity="success" sx={{ width: '100%' }} > {state.messeger} </Alert>
            </Snackbar>

            <Outlet />
        </div>
    )
}

export default PointSale;