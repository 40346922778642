import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import HeaderBar from '../../components/HeaderBar';
import NavigateDrawer from '../../components/NavigationDrawer';
// import NavigationBar from '../../components/NavigationBar';

const Panel = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        drawerVisibled: false,
        isVisibled: { sales: false, products: false }
    });

    let { drawerVisibled } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        // console.log(1, user)
        if (user === null) navigate('/login');
    }

    const onVisibled = () => {
        setState({ ...state, drawerVisibled: !drawerVisibled });
    }

    return (
        <div style={{ width: '100vw' }} >
            {!location.pathname.includes('pointsale') ? <HeaderBar onVisibled={onVisibled} /> : null }
            {/* {!location.pathname.includes('pointsale') ? <NavigationBar /> : null } */}
            {!location.pathname.includes('pointsale') ? <NavigateDrawer drawerVisibled={drawerVisibled} onVisibled={onVisibled} /> : null }
            <Outlet />
        </div>
    )
}

export default Panel;