
export class TypeApplicationContext {
    id = 0; 
    userName = ''; 
    lastName = ''; 
    userCPF = ''; 
    password = ''; 
    privilege = ''; 
    codeCompany = 0; 
    access = false; 
    db_name = '';
    created = ''; 
    updated = ''; 
    note = '';
    isLogged = false;
    dateTime = '';
    token = '';
}