import { Routes, Route } from "react-router-dom";

import MobileHome from "./pages/Home";
import MobileLogin from "./pages/Login";
import MobilePanel from "./pages/Panel";
import MobileProducts from "./pages/Products";
import MobileCustomers from "./pages/Customers";
import MobileSales from "./pages/Sales";

/** VIEWS */
import MobileSalePreview from "./views/sales/Preview";
import MobilePointSales from "./views/sales/PointSales";
import MobilePayment from "./views/sales/Payment";

const Router = () => {
    return (
        <Routes>
            <Route index path="/" element={<MobileHome />} />
            <Route path="/login" element={<MobileLogin />} />
            <Route path="/panel" element={<MobilePanel />} />
            <Route path="/products" element={<MobileProducts />} />
            <Route path="/customers" element={<MobileCustomers />} />
            <Route path="/sales" element={<MobileSales />} >
                <Route index element={<MobileSalePreview />} />
                <Route path="pointsales" element={<MobilePointSales />} />
                <Route path="payment" element={<MobilePayment />} />
            </Route>
        </Routes>
    )
}

export default Router;