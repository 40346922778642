import React from 'react';
import styles from './styles.module.css';
// import { Typography, Button } from '@mui/material';

const CustomersReport = () => {

    return (
        <div className={styles.container} >

            Customers Report

        </div>
    )
}

export default CustomersReport;