import React, { useState } from 'react';
import styles from './styles.module.css';
import MobileHeaderBar from '../../components/HeaderBar';
import MobileNavigateDrawer from '../../components/NavigateDrawer';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const MobileCustomers = () => {

    const navigate = useNavigate();

    const [state, setState] = useState({
        drawerVisibled: false,
    });

    let { drawerVisibled } = state;

    const onVisibled = () => {
        setState({ ...state, drawerVisibled: !drawerVisibled });
    }

    return (
        <div className={styles.container} >
            <MobileHeaderBar onVisibled={onVisibled} />
            <MobileNavigateDrawer drawerVisibled={drawerVisibled} onVisibled={onVisibled} />

            Mobile Customers
            <Button onClick={() => navigate('/panel', { replace: true })} > Voltar </Button>
        </div>
    )
}

export default MobileCustomers;