
export const onFormatCPF = (value) => {
    let inputValue = '';
    inputValue = value;
    inputValue = inputValue.replace(/[^\d\s-/]/g, "");
    inputValue = inputValue.replace('-', '');

    if (inputValue.length === 3) {
        inputValue = inputValue.concat('.');
    } else if (inputValue.length >= 4 && inputValue.length <= 6) {
        inputValue = inputValue.substring(0,3).concat('.').concat(inputValue.substring(3));

    }  else if (inputValue.length >= 7 && inputValue.length <= 9) {
        inputValue = inputValue.substring(0,3).concat('.').concat(inputValue.substring(3,6)).concat('.').concat(inputValue.substring(6));
    
    } else if (inputValue.length >= 10) {
        inputValue = inputValue.substring(0,3).concat('.').concat(inputValue.substring(3,6)).concat('.').concat(inputValue.substring(6,9)).concat('-').concat(inputValue.substring(9));
    }

    return inputValue;
}

export const removeCaracters = (value) => {
    let inputValue = '';
    inputValue = value;
    inputValue = inputValue.replace(/[^\d\s-/]/g, "");
    inputValue = inputValue.replace('.', "");
    inputValue = inputValue.replace('-', "");
    // inputValue = inputValue.replace(/./g, "");
    // inputValue = inputValue.replace(/-/g, "");
    return inputValue;
}
