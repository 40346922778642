import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Alert, Button, CircularProgress, Snackbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { onProductsList } from '../../../../serverless/products';
import { local_db } from '../../../../utils/local_db';

const MobileSalePreview = () => {

    const navigate = useNavigate();

    const [state, setState] = useState({
        isLoading: true,
        messeger: ''
    });

    let { isLoading, messeger } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        try {
            const products = await onProductsList();
            await local_db?.products.clear();
            await local_db?.products.add(products);
            messeger = 'Dados atualizados';
        } catch (error) {
            messeger = 'Dados não atualizados';
        }
        setState({ ...state, isLoading: false, messeger });
    }

    return (
        <div className={styles.container} >

            <div className={styles.isLoading} style={{ display: isLoading ? 'flex' : 'none' }} >
                <CircularProgress color='success' size={25} />
                <Typography> carregando... </Typography>
            </div>

            <Button className={styles.btPointSale} disabled={isLoading} vi variant='outlined' onClick={() => navigate('pointsales', { replace: true })} > Ponto de Vendas </Button>

            <Snackbar open={messeger ? true : false} onClose={() => setState({ ...state, messeger: '' })} autoHideDuration={2000} >
                <Alert color='success' onClose={() => setState({ ...state, messeger: '' })} > {messeger} </Alert>
            </Snackbar>

        </div>
    )
}

export default MobileSalePreview;