
import React, { useEffect, useState } from "react";
import styles from './styles.module.css';
import moment from "moment";
import { apiOnSaleListDay } from "../../../../serverless/sales";
import { TypeSales } from "../../../../interfaces/sales";
import { CircularProgress, Typography } from "@mui/material";
// import { convertCurrency } from '../../../../utils/NumberFormt';

const SalesStatus = () => {

    const [state, setState] = useState({
        listDay: [new TypeSales()],
        listMonth: [new TypeSales()],
        isLoading: 'loading',
    });

    let { listDay, listMonth, isLoading } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        setState({ ...state, listDay: listDay.splice(0), listMonth: listMonth.splice(0) });

        try {
            const apiDate = {
                dateInit: moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss'),
                dateLast: moment().set({ hour: 23, minute: 59, second: 59 }).subtract(3, 'hours').format('YYYY-MM-DD HH:mm:ss'),
            }

            const apiDateMonth = {
                dateInit: moment().set({ month: moment().month(), date: 1, hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss'),
                dateLast: moment().set({ month: moment().month(), date: moment().daysInMonth(), hour: 23, minute: 59, second: 59 }).subtract(3, 'hours').format('YYYY-MM-DD HH:mm:ss'),
            }

            // let d = moment().set({
            //     month: moment().month(),
            //     date: moment().daysInMonth(),
            //     hour: 23,
            //     minute: 59,
            //     second: 59
            // }).format('YYYY-MM-DD HH:mm:ss')

            listDay = await apiOnSaleListDay(apiDate);
            listMonth = await apiOnSaleListDay(apiDateMonth);

            // console.log(apiDate, apiDateMonth);

            setState({ ...state, listDay, listMonth, isLoading: 'ok' });
            
        } catch (error) {
            console.log('ERRO INIT -> ', error);
            setState({ ...state, isLoading: 'err' });
        }
    }

    const onTotalSume = (key) => {
        let total = 0;
        if (key === 'day') {
            listDay?.map(i => total += Number.parseFloat(i.value_sale));
        } else if (key === 'month') {
            listMonth?.map(i => total += Number.parseFloat(i.value_sale));
        }
        return Number.parseFloat(total.toFixed(2)) || 0;
    }

    return (
        <div className={styles.container} >
            {isLoading === 'loading' ? <CircularProgress size={50} /> : null}

            <Typography fontSize={40} style={{ display: isLoading === 'ok' ? 'block' : 'none' }} > Hoje: {onTotalSume('day') || 'sem registro'} </Typography>
            <Typography fontSize={30} style={{ display: isLoading === 'ok' ? 'block' : 'none' }} > Mês: {onTotalSume('month') || 'sem registro'} </Typography>

            {/* {isLoading === 'ok' ?
                listDay?.map((day, key) => (
                    <div key={key} >
                        <Typography> {day.value_sale} </Typography>
                    </div>
                ))
                : null}

            {isLoading === 'ok' ?
                listMonth?.map((month, key) => (
                    <div key={key} >
                        <Typography> {month.value_sale} </Typography>
                    </div>
                ))
                : null} */}
        </div>
    )
}

export default SalesStatus;