import React from 'react';
import styles from './styles.module.css';
import { Typography, Button } from '@mui/material';
import { GoPackage } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';

const Status = () => {

    const navigate = useNavigate();

    return (
        <div className={styles.container} >

            <Typography> Escolha seu módulo de trabalho </Typography>

            <div className={styles.nav} >
                {/* <Button variant='outlined' endIcon={<GoPackage />} onClick={() => window.open('http://localhost:7777/panel/products', "_blank", "popup")} > Produtos </Button> */}
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('products', { state: 1 })} > Produtos </Button>
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('services', { state: 2 })} > Serviços </Button>
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('sales', { state: 3 })} > Vendas </Button>
            </div>

            <div className={styles.nav} >
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('pointsale', { state: 4 })} > Ponto de Venda </Button>
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('pointsale', { state: 4.1 })} > Pré-venda </Button>
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('pointsale', { state: 4.2 })} > Orçamentos </Button>
            </div>

            <div className={styles.nav} >
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('customers', { state: 5 })} > Clientes </Button>
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('suggestions', { state: 6 })} > Sugestões </Button>
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('reports', { state: 7 })} > Relatórios </Button>
            </div>

            <div className={styles.nav} >
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('settings', { state: 8 })} > Configurações </Button>
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('settings', { state: 9 })} > Acessos </Button>
                <Button variant='outlined' endIcon={<GoPackage />} onClick={() => navigate('settings', { state: 10 })} > Suporte </Button>
            </div>

        </div>
    )
}

export default Status;