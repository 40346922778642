import axios from 'axios';

const URL = process.env.REACT_APP_BASIC_URL;

export const serverTest = axios.create({
    baseURL: URL,
    headers: { "Content-Type": "application/json" },
    timeout: 20000,
    responseType: 'json'
});

export const serverUser = axios.create({
    baseURL: URL.concat('user'),
    headers: { "Content-Type": "application/json" },
    // timeout: 20000,
    responseType: 'json'
});

export const serverProducts = axios.create({
    baseURL: URL.concat('products'),
    headers: { "Content-Type": "application/json" },
    timeout: 20000,
    responseType: 'json'
});

export const serverAddress = axios.create({
    baseURL: URL.concat('address'),
    headers: { "Content-Type": "application/json" },
    timeout: 20000,
    responseType: 'json'
});

export const serverProviders = axios.create({
    baseURL: URL.concat('providers'),
    headers: { "Content-Type": "application/json" },
    timeout: 20000,
    responseType: 'json'
});

export const serverBrand = axios.create({
    baseURL: URL.concat('brand'),
    headers: { "Content-Type": "application/json" },
    timeout: 20000,
    responseType: 'json'
});

export const serverCustomers = axios.create({
    baseURL: URL.concat('customers'),
    headers: { "Content-Type": "application/json" },
    timeout: 20000,
    responseType: 'json'
});

export const serverSales = axios.create({
    baseURL: URL.concat('sales'),
    headers: { "Content-Type": "application/json" },
    timeout: 20000,
    responseType: 'json'
});

// export { serverTest, serverUser, serverProducts, serverAddress, serverProviders, serverBrand };