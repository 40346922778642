import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { TypeProducts } from '../../../../interfaces/products';
import { TextField, Typography, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { apiOnGetProducts, onProductsExitSave } from '../../../../serverless/products';

const ExitProducts = () => {

    const location = useLocation();

    const [state, setState] = useState({
        product: new TypeProducts(),
        values: { qtde: '', note: '' },
        codeBarInput: '',
        heightInput: 45,
        btDisabled: false,
    });

    let { product, codeBarInput, heightInput, values, btDisabled } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = () => {
        const stateProduct = location.state;
        if (stateProduct) {
            setState({ ...state, product: stateProduct });
        }
    }

    const onState = (key, value) => {
        if (key === 0) {
            codeBarInput = value;
        } else if (key === 1) {
            values.qtde = value;
        } else if (key === 2) {
            values.note = value;
        }

        setState({ ...state, codeBarInput, values });
    }

    const onGetProduct = () => {
        setState({ ...state, btDisabled: true });
        apiOnGetProducts(codeBarInput).then((response) => {
            if (response.length) {
                product = response[0];
            } else {
                alert('Produto não encontrado');
            }
            setState({ ...state, btDisabled: false, product, codeBarInput: '' });

        }).catch((err) => {
            alert('Não foi possível buscar o produto.');
            setState({ ...state, btDisabled: false });
        })
    }

    const onSaveProduct = () => {
        setState({ ...state, btDisabled: true });
        const dataSave = { qtde: values.qtde, codeBar: product.codeBar };
        onProductsExitSave(dataSave).then(() => {
            alert('Saída de produtos realizada com sucesso.');
            product.qtde -= Number.parseInt(values.qtde);
            setState({ ...state, product, btDisabled: false });
        }).catch((err) => {
            console.log(err);
            alert('Erro. Tente novamente.')
        })
    }

    const onCleanForm = () => {
        setState({ ...state, codeBarInput: '', product: new TypeProducts(), values: { qtde: '', note: '' }, btDisabled: false })
    }


    return (
        <div className={styles.container} >

            <Typography className={styles.title} > Saída de Produtos </Typography>

            <div className={styles.rowBox} >

                <TextField
                    type={'search'}
                    className={styles.inputs}
                    disabled={btDisabled}
                    autoComplete='off'
                    label='Código Produto'
                    InputProps={{ style: { height: heightInput } }}
                    InputLabelProps={{ shrink: true }}
                    value={codeBarInput}
                    onKeyDown={(e) => e.key === 'Enter' ? onGetProduct() : {}}
                    onChange={(e) => onState(0, e.target.value)}
                />

            </div>

            <div className={styles.rowDescripion} >
                <Typography > {product?.name || '--------------'} </Typography>
                <Typography > {product.codeBar ? `Código: ` : ''} <b>{product?.codeBar}</b> </Typography>
                <Typography > {product.qtde ? `Estoque:` : ''} <b>{product?.qtde}</b> </Typography>
            </div>

            <div className={styles.rowBox} style={{ marginTop: '6vh' }} >
                <TextField
                    className={styles.inputs}
                    type='number'
                    autoComplete='off'
                    placeholder='0'
                    label='Quantidade'
                    InputProps={{ style: { height: heightInput } }}
                    InputLabelProps={{ shrink: true }}
                    value={values?.qtde}
                    onChange={(e) => e.target.value.length < 10 ? onState(1, e.target.value) : {}}
                />

                <TextField
                    className={styles.inputs}
                    placeholder='Digite'
                    autoComplete='off'
                    label='Nota'
                    InputProps={{ style: { height: heightInput } }}
                    InputLabelProps={{ shrink: true }}
                    value={values?.note}
                    onChange={(e) => e.target.value.length < 10 ? onState(2, e.target.value) : {}}
                />
            </div>

            <Button
                className={styles.addBT}
                disabled={btDisabled ? true : (product?.id > 0 && values?.qtde !== '') ? false : true}
                variant='contained'
                onClick={onSaveProduct}
            >Aplicar</Button>

            <Button
                className={styles.addBT}
                disabled={btDisabled}
                variant='contained'
                color='warning'
                onClick={onCleanForm}
            >Limpar</Button>

        </div>
    )
}

export default ExitProducts;