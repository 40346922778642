
export class TypeSales {
    id = 0;
    value_sale = 0;
    date_sale = '';
    qtde_itens = 0;
    format_payment = ''; 
    note_sale = '';
    id_IAM = 0; 
    details = ''; 
    name_client = ''; 
    cpf_client = '';
}