import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { TypeProducts } from '../../../../interfaces/products';
import { Typography, TextField, MenuItem, Switch, FormControlLabel, Button } from '@mui/material';
import { apiOnGetProducts, apiOnProductsEditeSave } from '../../../../serverless/products';
import { onFormatCurrent } from '../../../../utils/currenty';
import { convertCurrency } from '../../../../utils/NumberFormt';

const EditeProducts = () => {

    const location = useLocation();

    const [state, setState] = useState({
        inputSearch: '',
        product: new TypeProducts(),
        productNew: { ...(new TypeProducts()), equals: true },
    });

    let { inputSearch, product, productNew } = state;

    useEffect(() => {
        // console.log(product)
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = () => {
        // console.log(location.state)
        if (location.state) {
            product = location.state;
            productNew = { ...location.state, equals: true };
            setState({ ...state, product, productNew });
        }
    }

    const onState = (key, value) => {
        if (key === 0) {
            inputSearch = value;
        } else if (key === 'value') {
            productNew[key] = onFormatCurrent(value);
            productNew.equals = false;
        } else {
            productNew[key] = value;
            productNew.equals = onDisabledBts();
        }


        setState({ ...state, inputSearch, productNew });
    }

    const onSearch = () => {

        apiOnGetProducts(inputSearch).then((response) => {
            if (response.length) {
                product = response[0];
                productNew = { ...response[0], equals: true };
                // console.log(product.name)
            } else {
                alert('Produto não encontrado');
            }

            setState({ ...state, product, productNew });
        }).catch((err) => {
            alert('Erro. Tente novamente.');
        })
    }

    const onDisabledBts = () => {
        const isDisabledArr = [];
        if (productNew.codeBar !== product.codeBar) isDisabledArr.push(true);
        if (productNew.name !== product.name) isDisabledArr.push(true);
        if (productNew.descripition !== product.descripition) isDisabledArr.push(true);

        if (productNew.measure !== product.measure) isDisabledArr.push(true);
        if (Number.parseInt(productNew.qtde) !== product.qtde) isDisabledArr.push(true);
        if (productNew.note !== product.note) isDisabledArr.push(true);
        if (productNew.active !== product.active) isDisabledArr.push(true);

        // if (Number.parseFloat(productNew.value) > Number.parseFloat(product.value)) isDisabledArr.push(true);
        // if (productNew.value.toString() !== product.value.toString()) console.log(productNew.value.toString(), product.value.toString());
        // if (productNew.last_supplier !== product.last_supplier) isDisabledArr.push(true); 

        return !!!isDisabledArr.filter(is => is).length;
    }

    const onSaveEdite = () => {
        const dataSave = { ...productNew };
        dataSave.value = convertCurrency(productNew.value);
        delete dataSave.equals;
        delete dataSave.created;
        delete dataSave.updated;
        delete dataSave.last_iam;

        apiOnProductsEditeSave(dataSave).then(() => {
            // console.log(response);
            product = { ...productNew };
            delete product?.equals;
            productNew.equals = onDisabledBts();

            setState({ ...state, product, productNew });
            alert('Atualizado');
        }).catch((err) => {
            console.log('ERRO: ', err);
            alert('Não Atualizado');
        })
    }

    const onCleanForm = () => {
        product = new TypeProducts();
        productNew = { ...(new TypeProducts()), equals: true };
        setState({ ...state, product, productNew });
    }

    return (
        <div className={styles.container} >

            <Typography className={styles.title} > Editar Produto </Typography>

            <div className={styles.boxSearch} >
                <TextField
                    type='search'
                    placeholder='Digite'
                    label='Código produto'
                    autoComplete='off'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 45 } }}
                    value={inputSearch}
                    onKeyDown={(e) => (e.key === 'Enter' && inputSearch.length >= 5) ? onSearch() : {}}
                    onChange={(e) => onState(0, e.target.value)}
                />
            </div>

            <div className={styles.boxDescripition} >
                <Typography> ID: {product?.id > 0 ? <b>{product?.id}</b> : ' ___'} </Typography>
                <Typography> Criado: {product?.created.length ? <b>{moment(product?.created).subtract(3, 'hours').format('DD/MM/YYYY')} </b> : ' ___'} </Typography>
                <Typography> Atualizado: {product?.updated.length ? <b>{moment(product?.updated).subtract(3, 'hours').format('DD/MM/YYYY')}</b> : ' ___'} </Typography>
                <Typography> Atualizado por: {product?.last_iam > 0 ? <b>{product?.last_iam}</b> : ' ___'} </Typography>
            </div>

            <div className={styles.boxEdite} >
                <TextField
                    autoComplete='off'
                    label='Código barras'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 45 } }}
                    defaultValue={product?.codeBar}
                    value={productNew?.codeBar}
                    onChange={(e) => onState('codeBar', e.target.value)}
                />

                <TextField
                    autoComplete='off'
                    label='Nome'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 45 } }}
                    defaultValue={product?.name}
                    value={productNew?.name}
                    onChange={(e) => onState('name', e.target.value)}
                />

                <TextField
                    autoComplete='off'
                    label='Descrição'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 45 } }}
                    defaultValue={product?.descripition}
                    value={productNew?.descripition}
                    onChange={(e) => onState('descripition', e.target.value)}
                />
            </div>

            <div className={styles.boxEdite} >
                <TextField
                    select
                    autoComplete='off'
                    placeholder='Selecione'
                    label='Unidade de Medida'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 45 } }}
                    defaultValue={'UN'}
                    value={productNew?.measure}
                    onChange={(e) => onState('measure', e.target.value)}
                >
                    <MenuItem value={'UN'} > UN </MenuItem>
                    <MenuItem value={'KG'} > KG </MenuItem>
                </TextField>

                <TextField
                    type='number'
                    autoComplete='off'
                    placeholder='Digite'
                    label='Estoque'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 45 } }}
                    defaultValue={product?.qtde}
                    value={productNew?.qtde}
                    onChange={(e) => onState('qtde', e.target.value)}
                />

                <TextField
                    autoComplete='off'
                    placeholder='Digite'
                    label='Valor'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 45 } }}
                    // defaultValue={product?.value}
                    value={productNew?.value}
                    onChange={(e) => e.currentTarget.value.length <= 9 ? onState('value', e.currentTarget.value) : {}}
                />
            </div>

            <div className={styles.boxEdite} >

                <TextField
                    autoComplete='off'
                    placeholder='Digite'
                    label='Nota'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 45 } }}
                    defaultValue={product?.note}
                    value={productNew?.note}
                    onChange={(e) => onState('note', e.target.value)}
                />

                <TextField
                    disabled
                    select
                    autoComplete='off'
                    placeholder='Selecione'
                    label='Fornecedor'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ style: { height: 45 } }}
                    // defaultValue={product?.last_supplier}
                    // value={productNew?.last_supplier}
                    onChange={(e) => onState('last_supplier', e.target.value)}
                >
                    <MenuItem  > Amazon </MenuItem>
                    <MenuItem  > Magazine Luiza </MenuItem>
                    <MenuItem  > Kabun </MenuItem>
                </TextField>

                <FormControlLabel label='Ativo' control={<Switch checked={productNew?.active ? true : false} onChange={(e) => onState('active', e.target.checked)} />} />
            </div>

            <div className={styles.boxBTs} >
                <Button onClick={onCleanForm} disabled={productNew.equals} variant='contained' color='warning' >Limpar</Button>
                <Button onClick={onSaveEdite} disabled={productNew.equals} variant='contained' >Salvar alterações</Button>
            </div>

        </div>
    )
}

export default EditeProducts;