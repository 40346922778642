import React from 'react';
import styles from './styles.module.css';
import { Button } from '@mui/material';
import { BsListUl, BsPlus } from 'react-icons/bs';
import { MdLaunch, MdEdit } from 'react-icons/md';
import { IoMdExit } from 'react-icons/io';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

const Products = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const pathView = location.pathname.substring(16);
    
    // console.log('LOCATION -> ', location.pathname.substring(16))

    const optionsList = [
        { id: 0, title: 'Lista', path: '', icon: <BsListUl /> },
        { id: 1, title: 'Adicionar', path: 'add', icon: <BsPlus /> },
        { id: 2, title: 'Entrada', path: 'entry', icon: <MdLaunch /> },
        { id: 3, title: 'Saída', path: 'exit', icon: <IoMdExit /> },
        { id: 3, title: 'Editar', path: 'edite', icon: <MdEdit /> },
        { id: 4, title: 'Relatórios', path: 'report', icon: <HiOutlineDocumentReport /> },
    ];

    return (
        <div className={styles.container} >

            <div className={styles.optionsBar} >

                {optionsList.map((option, key) => (
                    <Button
                        key={key}
                        onClick={() => navigate(option.path, { replace: true })}
                        className={styles.optionsBarBt}
                        variant={pathView === option.path ? 'outlined' : 'contained'}
                        style={{ color: pathView === option.path ? 'silver' : '' }}
                        disabled={pathView === option.path ? true : false}
                        endIcon={option.icon} > {option.title} </Button>
                ))}

            </div>

            <Outlet />

        </div>
    )
}

export default Products;